import {
    createEntityComment,
    getEntityComments,
    getCommentsReasons
  } from "../../Services/Comments/comments";
  import {
    CREATE_COMMENT,
    GET_COMMENTS,
    GET_COMMENT_REASONS,
    SHOW_LOADER,
    HIDE_LOADER
  } from "../Types";
  import { actionDispatch, triggerNotification } from "./";
  
  export const createComment = (data) => async dispatch => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await createEntityComment(data);
    dispatch(actionDispatch(CREATE_COMMENT, response));
    if(response.success){
        dispatch(actionDispatch(HIDE_LOADER));
    }
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      triggerNotification("error", ex.message)
    );
  }
  }

  export const getComments = (id, data) => async dispatch => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await getEntityComments(id, data);
    dispatch(actionDispatch(GET_COMMENTS, response));
    if(response.success) dispatch(actionDispatch(HIDE_LOADER))
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      triggerNotification("error", ex.message)
    );
  }
  }

  export const getCommentReasons = (id, type) => async dispatch => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await getCommentsReasons(id, type);
    dispatch(actionDispatch(GET_COMMENT_REASONS, response));
   if(response.success)  dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      triggerNotification("error", ex.message)
    );
  }
  }