import React, { Fragment } from "react";
import moment from "moment";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold",
    fontSize: 10,
  },
  lessColDesc: {
    width: "60%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 10,
  },
  greaterColDesc: {
    width: "30%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 10,
  },
  indexStyle: {
    width: "10%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 10,
  },
});

const TableRows = (props) => {
  switch (props.label) {
    case "PO_INVOICE_COUNT_WITH_STATUS_REPORT":
      return generalTableRows(props);
    case "PROCESSED_PO_INVOICE_WITH_COUNT":
      return processedPOsAndInvoices(props);
    case "TOTAL_NUMBER_OF_CONTRACTS":
      return totalNumberOfContracts(props);
    case "PROCESSED_CONTRACTS_WITH_TIME_DIFFERENCE":
      return processedTimeOfContracts(props);
    case "LOG_IN_FREQUENCY":
      return userLoginFrequency(props);
    case "APPROVED_REJECTED_PO_INVOICE_PERCENTAGE":
      return approvedRejectedPercentPoInvoices(props);
    case "DISCREPANCY_AGGREGATE_PO":
      return discAggregatePO(props);
    case "DISCREPANCY_AGGREGATE_INVOICE":
      return discAggregateInvoice(props);
  }
};

export default TableRows;

/// Row wise sequence for each report to be called from TableRows Function ///
const generalTableRows = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.lessColDesc}>{index + 1}</Text>
        <Text style={styles.lessColDesc}>
          {item.organization ? item.organization.name : "--"}
        </Text>
        <Text style={styles.lessColDesc}>
          {item.vendor ? item.vendor.title : "--"}
        </Text>
        <Text style={styles.lessColDesc}>
          {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "--"}
        </Text>
        <Text style={styles.lessColDesc}>
          {item.po_number === undefined ? "Invoice" : "PO"}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const processedPOsAndInvoices = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item.organization ? item.organization.name : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.vendor ? item.vendor.title : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.amount
            ? `$${item.amount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "N/A"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.status ? item.status : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.po_number === undefined ? "Invoice" : "PO"}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const totalNumberOfContracts = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item.organization ? item.organization.name : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.vendor ? item.vendor.title : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.contract_type ? item.contract_type : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.updatedAt ? moment(item.updatedAt).format("YYYY-MM-DD") : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.status ? item.status : ""}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const processedTimeOfContracts = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item.contract_number ? item.contract_number : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item["organization.name"] ? item["organization.name"] : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item["vendor.title"] ? item["vendor.title"] : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.contract_type ? item.contract_type : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.createdAt ? moment(item.createdAt).format("MMM Do YY, h:mm a") : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.updatedAt ? moment(item.updatedAt).format("MMM Do YY, h:mm a") : ""}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.contractProcessedTime ? item.contractProcessedTime : ""}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const userLoginFrequency = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item && item.organization ? item.organization.name : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.user
            ? `${
                item.user.first_name
                  ? item.user.first_name
                  : "" + " " + item.user.last_name
                  ? item.user.last_name
                  : ""
              }`
            : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.user && item.user.email ? item.user.email : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.createdAt
            ? moment(item.createdAt).format("MMM Do YYYY, h:mm a")
            : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.roles && item.roles.map((role) => <Text>{role} </Text>)}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const approvedRejectedPercentPoInvoices = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item && item.organization_name ? item.organization_name.name : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.approved ? item.approved : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.rejected ? item.rejected : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.approved_percentage
            ? `${item.approved_percentage}%`
            : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.rejected_percentage
            ? `${item.rejected_percentage}%`
            : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.total ? item.total : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.type ? item.type : "--"}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const discAggregatePO = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item && item.po_number ? item.po_number : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.sku_number ? item.sku_number : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.organization ? item.organization.name : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.vendor ? item.vendor.title : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.amount
            ? `$${item.amount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "N/A"}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};

const discAggregateInvoice = (props) => {
  const rows =
    props.items &&
    props.items.map((item, index) => (
      <View style={styles.row}>
        <Text style={styles.greaterColDesc}>{index + 1}</Text>
        <Text style={styles.greaterColDesc}>
          {item && item.invoice_number ? item.invoice_number : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.sku_number ? item.sku_number : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.organization ? item.organization.name : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.vendor ? item.vendor.title : "--"}
        </Text>
        <Text style={styles.greaterColDesc}>
          {item && item.amount
            ? `$${item.amount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "N/A"}
        </Text>
      </View>
    ));
  return <Fragment>{rows}</Fragment>;
};
