import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import Header from "./Header";
import DefaultContainer from "../Container/default-container";
import { useLocation } from "react-router-dom";
import {
  DEMO_FORM,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
} from "../Constants/routeNames";
import { getFromLocal } from '../utils/cache';
import { LOCAL_STORAGE_KEYS } from '../utils/constants';

const MainLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [theme, setTheme] = useState('app-main-layout light-theme');
   const selectedTheme = useSelector((state) => state.header.theme);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const location = useLocation()["pathname"];

  useEffect(() => {
    if(selectedTheme) {
      setTheme(`app-main-layout ${selectedTheme}`);
    } else {
      setTheme(`app-main-layout ${getFromLocal(LOCAL_STORAGE_KEYS.THEME, false)}`);
    }
  }, [selectedTheme]);

  const public_routes = [
    LOGIN_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    RESET_PASSWORD_ROUTE,
    DEMO_FORM,
  ];

  return (
    <div>
      {!public_routes.includes(location) && (
        <Layout className={theme}>
          <Sidebar collapsed={collapsed} />
          <Layout className="site-layout">
            <Header collapsed={collapsed} toggle={toggle} />
            <DefaultContainer />
          </Layout>
        </Layout>
      )}
    </div>
  );
};
export default MainLayout;
