import React, { useState } from "react"
import { Card, Col, Row } from "antd"
import { RightCircleFilled } from "@ant-design/icons";
import { setCurrentReportIndex } from "../actions/Creators";
import { useDispatch, useSelector } from "react-redux";
import Authorized from "../Role-Authorization/Authorized";

import "./Report.scss"

const ReportTiles = ({reports, setReportData}) => {
    const dispatch = useDispatch();
    const reportIndex = useSelector(
        (state) => state.reports.currentReportIndex
      );
    const [selectedIndex, setSelected] = useState(reportIndex)
    const onClickHandler = (report, index) => {
        dispatch(setCurrentReportIndex(index));
        setSelected(index);
        setReportData(report);
    }

    return (
        <div className="ant-layout-content report-filter-box-wrapper">
        <Row className="tiles-container">   
            {reports.map((report, index) => (
                <Authorized roleList={report.permissions}>
                 <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                <Tile
                  report={report}
                  index={index}
                  selectedIndex={selectedIndex}
                  onClickHandler={onClickHandler}
                />
                </Col>
              </Authorized>
            ))}       
        </Row>
    </div>
    )
}

const Tile = ({report, index,selectedIndex,  onClickHandler}) => {
    return (
       
            <Card className={`report-tile ${index== selectedIndex ? "report-tile__active " : ""}`} onClick ={() => onClickHandler(report, index)}>
                <p className="title">{report.title}</p>
                <RightCircleFilled className={`${index== selectedIndex ? "report-tile__active-icon " : "report-tile-icon"}`} />
            </Card>
        
    )
}

export default ReportTiles;