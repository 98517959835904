import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row, Form, Button, Spin, Input, DatePicker, Switch, InputNumber, Select } from 'antd';
import NumberFormat from "react-number-format";
import _ from "lodash";
import moment from "moment";
import FieldGenerator from "../../Common/FieldGenerators/FieldGenerator";
import { fetchTemplatesByContractId } from "../../actions/Creators/Templates";
import VendorDropdown from "../../Common/VendorDropdown";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import Notification from "../../Common/Notification";
import history from "../../History/history";
import {
  actionDispatch,
  getVendors,
  fetchContractsById,
  triggerNotification,
  updateTemplatedContract,
  getSelectedContractTemplate,
  clearContractFile,
} from "../../actions/Creators";
import { getFromLocal } from "../../utils/cache";
import Authorized from "../../Role-Authorization/Authorized";
import {
  ROLES_CONTRACTS_INVOICE_MANANGER,
  ROLES_CONTRACT_UPLOAD_MANAGER,
  ROLES_TRUPACTA_TEAM_VIEW,
} from "../../utils/constants";
import { isEmpty } from "../../utils/isEmpty";
import { isAuthorized } from "../../Services/Auth/auth";
import { FilePdfOutlined } from "@ant-design/icons";
import { openPDF } from "../../utils/s3Upload";

export default withRouter(({ match }) => {
  const { id } = match.params;
  const [tableForm] = Form.useForm();
  const [parentForm] = Form.useForm();
  const template = useSelector(
    (state) => state.templatesReducer.templatedContract
  );
  const loader = useSelector((state) => state.commonReducer.loader);
  const notification = useSelector((state) => state.commonReducer.notification);
  const rolesArr = useSelector((state) => state.auth.roles);
  const vendor = useSelector((state) => state.vendor);
  const contract = useSelector((state) => state.contract.contract);
  const fileUrl = useSelector((state) => state.contract.contractFileUrl);
  const fileLoader = useSelector((state) => state.commonReducer.fileLoader);
  const [selectedVendor, setSelectedVendor] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(false);
  const [rebates, setRebates] = useState(false);
  const [termination, setTermination] = useState(false);
  const [sla, setSla] = useState(false);
  const [tiered, setTiered] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState("0");
  
  const dispatch = useDispatch();
  const calculateNetPrice = () => {
    const updatedValues = tableForm.getFieldsValue();
    let afterDiscount = 0;
    let newobj = {};
    let maxRows = 0;
    let columnsList = Object.keys(updatedValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    let dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value) => {
        result[value] = updatedValues[`${value}-${index}`];
        return result;
      }, {});
    });
    dto.forEach((i, index) => {
      i.unit_price = i.unit_price && i.unit_price;
      i.discount = i.discount && i.discount;
      if (i.discount <= 100 && !isNaN(i.discount) && !isNaN(i.unit_price)) {
        afterDiscount = (i.discount / 100) * i.unit_price;
        i.final_price = i.unit_price - afterDiscount;
      } else {
        i.final_price = i.unit_price;
      }

      Object.keys(i).forEach((key) => {
        if (key === "final_price")
          newobj[`final_price-${index}`] = Number(i.final_price.toFixed(2));
      });

      tableForm.setFieldsValue({
        ...tableForm,
        ...newobj,
      });
    });
  };

  const removeFormatting = (numString) => {
    let numInt = numString.toString().replace(/[$,%\s]/g, "");
    return Number(numInt);
  };

  const onFileSelect = async (file) => {
    if (fileUrl) {
      await openPDF(fileUrl);
      dispatch(clearContractFile());
    }
  };

  const onSubmit = async (obj) => {
    tableForm.submit();
    let contractColumns = [
      "vendor",
      "title",
      "auto_renewal",
      "contract_number",
      "createdAt",
      "expiry",
      "initial_term",
      "extension_term",
      "min_order_quantity",
      "min_annual_quantity",
      "min_order_dollar_commitment",
      "min_annual_dollar_commitment",
      "yearly_price_increment_percentage",
      "rebates",
      "payment_terms",
      "termination",
      "sla",
      "tiered",
    ];
    let objectToSend = {
      values: {},
    };
    let contractToUpdate = {};
    const tableValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(tableValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value, colIndex) => {
        result[value] = tableValues[`${value}-${index}`];
        return result;
      }, {});
    });
    if (dto.length == 1 && _.isEmpty(dto[0])) {
      tableForm.resetFields();
      dispatch(triggerNotification("error", "SKU cannot be empty"));
      dispatch(actionDispatch(HIDE_NOTIFICATION));
      return;
    }

    if (
      obj.issue_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD")) ||
      obj.effective_date.isBefore(obj.issue_date.format("YYYY-MM-DD")) ||
      obj.effective_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD"))
    ) {
      // Check if expiry date is after issue date
      if (obj.issue_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD"))) {
        dispatch(
          triggerNotification(
            "error",
            "Expiry date should be greater than Issue date."
          )
        );
        dispatch(actionDispatch(HIDE_NOTIFICATION));
      }

      // Check if effective date is after issue date
      if (obj.effective_date.isBefore(obj.issue_date.format("YYYY-MM-DD"))) {
        dispatch(
          triggerNotification(
            "error",
            "Issue date should not be greater than effective date."
          )
        );
        dispatch(actionDispatch(HIDE_NOTIFICATION));
      }

      //Check if effective date is before expiry date
      if (obj.effective_date.isSameOrAfter(obj.expiry.format("YYYY-MM-DD"))) {
        dispatch(
          triggerNotification(
            "error",
            "Expiry date should be greater than Effective date."
          )
        );
        dispatch(actionDispatch(HIDE_NOTIFICATION));
      }

      return;
    }

    objectToSend.organization_id = template.contractDetails.organization_id;
    objectToSend.contract_id = template.contractDetails.id;
    objectToSend.template = template.template._id;
    contractToUpdate.issue_date = moment(obj.issue_date).format("YYYY-MM-DD");

    contractToUpdate.expiry = moment(obj.expiry).format("YYYY-MM-DD");
    contractToUpdate.title = obj.title;
    contractToUpdate.vendor = !_.isEmpty(selectedVendor)
      ? selectedVendor
      : getDefaultVendor();
    contractToUpdate.effective_date =
      obj.effective_date && moment(obj.effective_date).format("YYYY-MM-DD");
    contractToUpdate.contract_type = obj.contract_type;
    contractToUpdate.auto_renewal = obj.auto_renewal;
    contractToUpdate.rebates = obj.rebates;
    contractToUpdate.payment_terms = obj.payment_terms;
    contractToUpdate.termination = obj.termination;
    contractToUpdate.sla = obj.sla;
    contractToUpdate.tiered = obj.tiered;
    contractToUpdate.contract_number = obj.contract_number;
    contractToUpdate.contract_id = template.contractDetails.id;
    contractToUpdate.organization_id = template.contractDetails.organization_id;
    contractToUpdate.initial_term = obj.initial_term;
    contractToUpdate.extension_term = obj.extension_term;
    contractToUpdate.min_annual_dollar_commitment =
      obj.min_annual_dollar_commitment
        ? removeFormatting(obj.min_annual_dollar_commitment)
        : null;
    contractToUpdate.min_annual_quantity = obj.min_annual_quantity
      ? removeFormatting(obj.min_annual_quantity)
      : null;
    contractToUpdate.min_order_dollar_commitment =
      obj.min_order_dollar_commitment
        ? removeFormatting(obj.min_order_dollar_commitment)
        : null;
    contractToUpdate.min_order_quantity = obj.min_order_quantity
      ? removeFormatting(obj.min_order_quantity)
      : null;
    contractToUpdate.yearly_price_increment_percentage =
      obj.yearly_price_increment_percentage
        ? removeFormatting(obj.yearly_price_increment_percentage)
        : null;

    objectToSend.values.sku = dto;
    obj.issue_date = moment(obj.issue_date).format("YYYY-MM-DD");
    for (let prop in obj) {
      if (!contractColumns.includes(prop)) {
        objectToSend.values[prop] = obj[prop];
      }
    }
    await tableForm.validateFields();
    delete objectToSend.values["vendor undefined"];
    const DTO = {
      templtedContractInfo: { ...objectToSend },
      contractInfo: { ...contractToUpdate },
    };
    dispatch(updateTemplatedContract(template.templatedContractId, DTO));
    parentForm.resetFields();
    tableForm.resetFields();
  };
  useEffect(() => {
    const organization = getFromLocal("SELECTED_ORGANIZATION", true, true);
    dispatch(getVendors(organization.id));
    dispatch(fetchTemplatesByContractId(id));
    dispatch(fetchContractsById(id));
    if (
      isAuthorized(
        [...ROLES_CONTRACTS_INVOICE_MANANGER, ...ROLES_CONTRACT_UPLOAD_MANAGER],
        rolesArr,
        ROLES_TRUPACTA_TEAM_VIEW[0]
      )
    )
      setIsDisabled(true);
  }, []);

  useEffect(() => {
    contract &&
      contract.document &&
      contract.document.key &&
      dispatch(getSelectedContractTemplate(contract.document.key));
  }, [contract]);

  useEffect(() => {
    if (!_.isEmpty(template.contractDetails)) {
      parentForm.setFieldsValue({
        ...template.contractDetails,
        ...template.templateValues,
        issue_date: template.contractDetails.issue_date
          ? moment(template.contractDetails.issue_date)
          : null,
        expiry: template.contractDetails.expiry
          ? moment(template.contractDetails.expiry)
          : null,
        effective_date: template.contractDetails.effective_date
          ? moment(template.contractDetails.effective_date)
          : null,
        "vendor undefined":
          vendor.vendors &&
          vendor.vendors.filter(
            (d) => d.id == template.contractDetails.vendor_id
          )[0] &&
          vendor.vendors.filter(
            (d) => d.id == template.contractDetails.vendor_id
          )[0].title,
      });
      template.templateValues.sku.forEach((obj, i) => {
        let newobj = {};
        Object.keys(obj).forEach((key) => {
          newobj[`${key}-${i}`] = obj[key];
        });
        tableForm.setFieldsValue({
          ...newobj,
        });
      });
    }
  }, [template]);

  const getDefaultVendor = () => {
    let obj = {};
    let vendorObj = vendor.vendors.filter(
      (d) => d.id == template.contractDetails.vendor_id
    )[0];
    obj.id = vendorObj.id;
    obj.value = vendorObj.title;
    return obj;
  };
  useEffect(() => {
    if (template.contractDetails) {
      setAutoRenewal(template.contractDetails.auto_renewal);
      setRebates(template.contractDetails.rebates);
      setTermination(template.contractDetails.termination);
      setSla(template.contractDetails.sla);
      setTiered(template.contractDetails.tiered);
      setPaymentTerms(template.contractDetails.payment_terms);
      parentForm.setFieldsValue({
        auto_renewal: template.contractDetails.auto_renewal,
      });
    }
  }, [template.contractDetails]);
  return (
    <Spin size="large" spinning={loader}>
      <Form
        layout="vertical"
        form={parentForm}
        // validateTrigger="onBlur"
        onFinish={(e) => onSubmit(e)}
      >
        <Row className="content-header">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Authorized roleList={ROLES_TRUPACTA_TEAM_VIEW}>
              <h2>Edit Contract</h2>
            </Authorized>
            <Authorized roleList={ROLES_CONTRACTS_INVOICE_MANANGER} shouldNotIncludePersona={ROLES_TRUPACTA_TEAM_VIEW}>
              <h2>View Contract</h2>
            </Authorized>
          </Col>
        </Row>

        <h4 className="mt-1">Contract Header</h4>

        <Row className="" gutter={24} align="top">
          <>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <VendorDropdown
                create
                vendor={vendor}
                defaultValue={
                  template.contractDetails &&
                  vendor.vendors &&
                  vendor.vendors.filter((d) => d.id == template.contractDetails.vendor_id)[0] &&
                  vendor.vendors.filter((d) => d.id == template.contractDetails.vendor_id)[0].title
                }
                label="Vendor"
                getValue={(v) => {
                  setSelectedVendor({ ...v });
                }}
                disabled={isDisabled}
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label="Contract Number"
                name="contract_number"
                rules={[
                  {
                    required: true,
                    message: 'please provide contract number',
                  },
                ]}
              >
                <Input maxLength={50} disabled={isDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label="Contract Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'please provide contract title',
                  },
                ]}
              >
                <Input maxLength={50} disabled={isDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Contract Category" name="contract_type">
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label="Contract Issue Date"
                name="issue_date"
                rules={[
                  {
                    required: true,
                    message: 'Please provide Contract Issue Date',
                  },
                ]}
              >
                <DatePicker disabled={isDisabled} placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label="Contract Effective Date"
                name="effective_date"
                rules={[
                  {
                    required: true,
                    message: 'Please provide contract effective date',
                  },
                ]}
              >
                <DatePicker disabled={isDisabled} placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label="Contract Renewal/Expiry Date"
                name="expiry"
                rules={[
                  {
                    required: true,
                    message: 'Please provide contract expiry date',
                  },
                ]}
              >
                <DatePicker disabled={isDisabled} placeholder="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Auto Renewal" name="auto_renewal">
                <Switch
                  disabled={isDisabled}
                  value={autoRenewal}
                  checked={autoRenewal}
                  onChange={(e) => setAutoRenewal(e)}
                />
              </Form.Item>
            </Col>
          </>
        </Row>

        <h4 className="mt-1">Contract Terms</h4>

        <Row className="" gutter={24} align="top">
          <>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Min order quantity" name="min_order_quantity">
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Min Annual Quantity" name="min_annual_quantity">
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Min Order dollar commitment" name="min_order_dollar_commitment">
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Min Annual Dollar commitment" name="min_annual_dollar_commitment">
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Yearly price Increment %" name="yearly_price_increment_percentage">
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  suffix={'%'}
                  maxLength={4}
                  max={100}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Initial term" name="initial_term">
                <Input.TextArea rows={3} maxLength={1500} showCount disabled={isDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Extension term" name="extension_term">
                <Input.TextArea rows={3} maxLength={1500} showCount disabled={isDisabled} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Payment terms" name="payment_terms">
                <Select value={paymentTerms} onChange={(e) => setPaymentTerms(e)}>
                  <Select.Option key="30" value={0}>
                    Net 30
                  </Select.Option>
                  <Select.Option key="30+" value={1}>
                    Net 30+
                  </Select.Option>
                  <Select.Option key="prepaid" value={2}>
                    Prepaid
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Rebates Available" name="rebates">
                <Switch disabled={isDisabled} value={rebates} checked={rebates} onChange={(e) => setRebates(e)} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Termination of Convenience" name="termination">
                <Switch
                  disabled={isDisabled}
                  value={termination}
                  checked={termination}
                  onChange={(e) => setTermination(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="SLA/Leadtime Credits" name="sla">
                <Switch disabled={isDisabled} value={sla} checked={sla} onChange={(e) => setSla(e)} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label="Tiered Pricing" name="tiered">
                <Switch disabled={isDisabled} value={tiered} checked={tiered} onChange={(e) => setTiered(e)} />
              </Form.Item>
            </Col>
          </>
        </Row>
        {!_.isEmpty(template) &&
          template.template.records.map((d, index) => {
            return (
              <Row justify="start" key={index}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <h4 className="mt-1">{d.label}</h4>

                  <Row justify="start" gutter={[24, 12]}>
                    {d.fields_set &&
                      d.fields_set.map((field, colIndex) => {
                        return (
                          <Col key={colIndex} span={field.col_type === 'table' ? 24 : 6}>
                            <FieldGenerator
                              calculateNetPrice={calculateNetPrice}
                              isDisabled={isDisabled}
                              tableForm={tableForm}
                              field_key={field.key}
                              values={template.templateValues}
                              {...field}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            );
          })}
        <Row className="mt-2">
          <Spin spinning={fileLoader}>
            {!isEmpty(contract.document && contract.document.key) && (
              <Row>
                <h4 className="mt-1">Uploaded Contract</h4>
                <Col span={24} key={contract.document.key}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onFileSelect(contract.document);
                    }}
                  >
                    <FilePdfOutlined /> {contract.document.name}
                  </a>
                </Col>
              </Row>
            )}
          </Spin>
        </Row>
        <Row className="mt-2" align="top">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button
              onClick={() => {
                history.goBack();
              }}
              className="btn-primary btn-t2 mr-1"
            >
              <span class="btn-icon icon-arrow-square-left"></span> Back
            </Button>
            <Button className="btn-primary" htmlType="submit">
              <span className="btn-icon icon-tick"></span> Submit
            </Button>
          </Col>
        </Row>
      </Form>
      {notification.open && notification.message ? (
        <Notification msg={notification.message} type={notification.type} clearMsg={() => {}} />
      ) : null}
    </Spin>
  );
});
