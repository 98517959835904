import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, Badge, Row, Col } from "antd";
import { Treemap } from '@ant-design/charts';
import Loader from "../Loader";

const TreeMap = ({ labelText = '', chartData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    }
  }, [chartData]);

  const config = {
    data: {
      name: 'root',
      children: data,
    },
    colorField: 'name',
    color: ['#addcde', '#83cbcd', '#5ababd', '#31a8ac', '#08979c'],
    legend: false,
    height: 300,
  };

  return (
    <Card className="dashboad-chart-box dashboard-card-t1 ">
      <Row className="mb-1">
        <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={10}>
          <h3>{labelText} </h3>
        </Col>
      </Row>
      {chartData && !_.isEmpty(data) ? <Treemap {...config} /> : <Loader />}
    </Card>
  );
};

export default TreeMap;
