import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Row, Col, Card, Typography, Switch, Divider } from "antd";

import { useDispatch, useSelector } from "react-redux";
import PieChart from "../../Common/Charts/Pie";
import ColumnChart from "../../Common/Charts/Column";
import LineChart from '../../Common/Charts/Line';
import BarChart from '../../Common/Charts/Bar';
import RadialChart from '../../Common/Charts/Radial';
import TreeMap from '../../Common/Charts/Treemap';
import Bubble from '../../Common/Charts/Bubble';
import Authorized from "../../Role-Authorization/Authorized";
import {
  ROLES_CONTRACTS_INVOICE_MANANGER,
  ROLES_TRUPACTA_TEAM_VIEW,
  ROLES_PO_ADD_VIEW,
  ROLES_DISCREPANCY_APPROVER_VIEW,
  ROLES_CONTRACT_UPLOAD_MANAGER,
  ROLES_INVOICE_MANAGER,
  ROLES_REPORT_VIEW,
} from "../../utils/constants";
import { badgeColor } from "../Contracts/ContractStatus";
import ReportDashboard from "./ReportDashboard";
import SimpleCardDashboard from "../../Common/Card/SimpleCardDashboard";
import {
  getContractRenewals,
  getTotalCounts,
  getDisrepencies,
  getProcessTrends,
  getTopSKUs,
  getTopVendors,
  getVendorScores,
} from '../../actions/Creators/Dashboard';
import { convertToInternationalCurrencySystem } from "./HelperFunctions";
import HeatMap from "../../Common/Charts/Heatmap";
import VendorScore from "./VendorScore";
import { checkSession } from "../../Services/Auth/auth"

const Dashboard = () => {
  const dispatch = useDispatch();

  const dashboard = useSelector((state) => state.dashboard);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );

  const vendor = useSelector((state) => state.header.selectedVendor);

  const checkActiveSession = async() => {
    const hasActiveSession = await checkSession();

    if(hasActiveSession && hasActiveSession.code === 200) {
      const v = vendor | 0;

      dispatch(getContractRenewals(v));
      dispatch(getTotalCounts(v));
      dispatch(getDisrepencies(v));
      dispatch(getProcessTrends(v));
      dispatch(getTopSKUs(v));
      dispatch(getTopVendors(v));
    }
  }
  
  useEffect(() => {
    checkActiveSession();
  }, [organization, vendor]);

  useEffect(() => {
    dispatch(getVendorScores('all'));
  }, []);

  const contractRenewal = dashboard.contractRenewal ? dashboard.contractRenewal.contracts: [];
  const processingTrend = dashboard.processingTrend ? dashboard.processingTrend : [];
  const discrepency = dashboard.discrepency;
  const topSku = dashboard.topSku ? dashboard.topSku : [];
  const topVendor = dashboard.topVendor ? dashboard.topVendor : [];
  const totalCount = dashboard.totalCount ? dashboard.totalCount : {};
  const vendorScores = dashboard.vendorScore ? dashboard.vendorScore : [];

  return (
    // <Spin size="large" spinner={loader}>
    <div className="dashboard-wrapper">
      <div className="col-left">
        <Authorized roleList={ROLES_REPORT_VIEW}>
          <ReportDashboard />
        </Authorized>

        <Row gutter={[24, 24]} className="db-row" justify="center">
          <Col xl={24}>
            <Card className="dashboard-number-box dashboard-card-t1 combine-data">
              <Row>
                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <SimpleCardDashboard                    
                    unit={totalCount.contracts}
                    title="Contracts"
                    boxClass="round-clr-t1"
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <SimpleCardDashboard
                    unit={totalCount.purchase_orders}
                    title="Purchase Order"
                    boxClass="round-clr-t2"
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <SimpleCardDashboard
                    unit={totalCount.invoices}
                    title="Invoices"
                    boxClass="round-clr-t3"
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <SimpleCardDashboard
                    unitSymbol="$"
                    unit={convertToInternationalCurrencySystem(totalCount.committed)}
                    title="Committed Spend"
                    boxClass="round-clr-t4"
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <SimpleCardDashboard
                    unitSymbol="$"
                    unit={convertToInternationalCurrencySystem(totalCount.spent)}
                    title="Actual Spend"
                    boxClass="round-clr-t5"
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <SimpleCardDashboard
                    unitSymbol="$"
                    unit={convertToInternationalCurrencySystem(totalCount.savings)}
                    title="Discrepancy Savings"
                    boxClass="round-clr-t6"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 24]} className="db-row" justify="center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <ColumnChart
              labelText="Contracts Renewal"
              dataSet={[
                {
                  xField: 'month',
                  yField: 'count',
                  data: contractRenewal,
                  color: ({ month }) => {
                    if (month === 'Overdue') {
                      return '#F4664A';
                    }

                    return '#13c2c2';
                  },
                },
              ]}
              types={['6 months', '12 Months']}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <PieChart labelText="Discrepancy" colors={['#F4664A', '#13c2c2']} uploadInfo={discrepency}/>
          </Col>
        </Row>

        <Row gutter={[24, 24]} className="db-row" justify="center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <LineChart labelText="Processing Trends" chartData={processingTrend} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            {/*<BarChart labelText="Top 5 SKU Spend" chartData={topSku} />*/}
            <TreeMap labelText="SKU Spend" chartData={topSku} />
          </Col>
        </Row>
      </div>

      <div className="col-right dashboard-side-chart">
        <VendorScore scores={vendorScores} vendorId={vendor} />

        <Row gutter={[0, 24]} className="dt-row" justify="start">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <RadialChart labelText="Top 5 Suppliers by Spend" chartData={topVendor}></RadialChart>
          </Col>
        </Row>
      </div>
    </div>
    // </Spin>
  );
};

export default Dashboard;
