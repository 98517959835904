import React, { useEffect } from "react";
import { notification } from "antd";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { actionDispatch } from "../../actions/Creators";
import { useDispatch } from "react-redux";

const Notification = ({ msg, type, clearMsg }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    notification[type]({
      description: msg,
    });
    dispatch(actionDispatch(HIDE_NOTIFICATION));
  }, []);
  return <div></div>;
};

export default Notification;
