import React from "react";
import { Row, Col, Upload, Button } from "antd";

const Uploader = ({ files, setFiles, type }) => {
  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    action: "/upload",
    accept: ".pdf",
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        setFiles(fileList);
      }
    },
    showUploadList: false,
    fileList: files,
  };
  return (
    <React.Fragment>
      <Row justify="center" className="upload-wrapper">
        <Col xl={24}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <span class="icon-upload-contract"></span>
            </p>
            <p className="label-upload">Drag and drop {type}. </p>
            <p className="label-or">or</p>

            <Button className="btn-upload">Upload</Button>
          </Dragger>
        </Col>
      </Row>
      <Row justify="right" className="uplaod-msg-wrapper">
        <Col xl={24}>
          <span>* Only pdf files supported</span>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Uploader;
