import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Badge,
  DatePicker,
  Input,
  Button,
  Radio,
  Form,
  Tooltip,
  Spin,
} from "antd";
import { Link, useLocation } from 'react-router-dom';
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import {
  actionDispatch,
  deleteContracts,
  getContracts,
  getVendors,
  setPagination,
  setRedirectStatus,
  clearSelectedContractUrlFile,
} from "../../actions/Creators";
import {
  UPLOAD_CONTRACT_ROUTE,
  EDIT_TEMPLATED_CONTRACT,
  VIEW_TEMPLATED_CONTRACT,
} from "../../Constants/routeNames";
import history from "../../History/history";
import Notification from "../../Common/Notification";
import VendorDropdown from "../../Common/VendorDropdown";
import Modal from "../../Common/Modal";
import { Status, badgeColor } from "./ContractStatus";
import Authorized from "../../Role-Authorization/Authorized";
import {
  ROLES_TRUPACTA_TEAM_VIEW,
  ROLES_CONTRACTS_ADD_VIEW,
  ROLES_CONTRACTS_INVOICE_MANANGER,
  ROLES_CONTRACT_UPLOAD_MANAGER,
} from "../../utils/constants";
import { openPDF } from "../../utils/s3Upload";
import { dateToLocal } from "../../utils/moment";

const ContractList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pagination = useSelector((state) => state.pagination.contract);
  const fileLoader = useSelector((state) => state.commonReducer.fileLoader);
  const [isPaginationSet, setIsPaginationSet] = useState(
    pagination.isRedirectedFromChild
  );
  const [selectedContractId, setSelectedContractId] = useState();
  const [selectedTemplate, setSetSelectedTemplate] = useState("");
  const [isModalOpen, setIsOpenModal] = useState({
    state: false,
    id: null,
  });
  const [page, setPage] = useState(
    pagination.page && isPaginationSet ? pagination.page : 1
  );
  const [clearField, setClearField] = useState(false);
  const [urlparams, setUrlParams] = useState({
    limit: pagination.limit && isPaginationSet ? pagination.limit : 10,
    skip:
      pagination.page && isPaginationSet
        ? pagination.page * pagination.limit - pagination.limit
        : 0,
    searchText: "",
    status: "",
    startDate: "",
    endDate: "",
    vendor: null,
  });
  const contract = useSelector((state) => state.contract);
  const [selVendor, setSelVendor] = useState(null);
  const vendor = useSelector((state) => state.vendor);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );

  useEffect(() => {
    dispatch(clearSelectedContractUrlFile());
    dispatch(getVendors(organization.id));

    const queryParams = new URLSearchParams(location.search);
    const p = { ...urlparams };
    const sDate = queryParams.get('startDate');
    if (sDate) {
      p.startDate = sDate;
    }
    const eDate = queryParams.get('endData');
    if (eDate) {
      p.endDate = eDate;
    }
    const ven = queryParams.get('vendor');
    if (ven) {
      p.vendor = ven;

      const selectedVendor = vendor.vendors.find((v) => v.id == ven);
      setSelVendor(selectedVendor? selectedVendor.title: '');
    }
    setUrlParams(p);
  }, []);

  useEffect(() => {
    dispatch(setRedirectStatus(false, "contract"));
    dispatch(getContracts(organization.id, urlparams));
    dispatch(
      setPagination(
        {
          page: page,
          limit: urlparams.limit,
        },
        "contract"
      )
    );
  }, [getContracts, urlparams]);

  const onRemove = async (id) => {
    await dispatch(deleteContracts(id, contract.contracts));
    if (contract.contracts.length < 2) {
      setPage(page - 1);
      setUrlParams({
        ...urlparams,
        skip: (page - 1) * urlparams.limit - urlparams.limit,
      });
    } else dispatch(getContracts(organization.id, urlparams));
    setIsOpenModal({ state: false, id: null });
  };

  const onOpenSelectedContract = (contract) => {
    setSelectedContractId(contract.id);
    setSetSelectedTemplate(contract.template_id);
    history.push(`/contract/${contract.id}/template/${contract.template_id}`);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serialNumber",
      width: 60,
      align: "center",
      render: (text, object, index) =>
        (page - 1) * urlparams.limit + (index + 1),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_id",
      ellipsis: true,
      width: 100,
      className: "bold-im",
      render: (vendor_id) =>
        vendor &&
        vendor.vendors
          .filter((vendor) => vendor_id === vendor.id)
          .map((vendor) => vendor.title),
    },
    {
      title: "Contract No.",
      dataIndex: "contract_number",
      width: 100,
      ellipsis: true,
      render: (contract_number) => (contract_number ? contract_number : "N/A"),
    },
    {
      title: "Contract Title",
      dataIndex: "title",
      ellipsis: true,
      width: 130,
    },
    // {   // as per client feedback : SMAR-179
    //   title: "Expiry/Renewal",
    //   dataIndex: "expiry",
    //   width: 160,
    //   render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "N/A"),
    // },
    {
      title: "Contract Category",
      dataIndex: "contract_type",
      ellipsis: true,
      width: 100,
      align: "left",
      render: (category) =>
        category && category.charAt(0).toUpperCase() + category.slice(1),
    },
    {
      title: "Added On",
      width: 100,
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
    },
    {
      title: "Processing Status",
      dataIndex: "status",
      width: 100,
      render: (text) => {
        let color;
        for (let prop in badgeColor)
          if (prop === text) {
            color = badgeColor[prop];
          }
        return (
          <div>
            <Badge color={color} />
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "view",
      width: 80,
      align: "right",
      fixed: "right",
      render: (text, row) => (
        <Row align="middle" justify="left">
          <Authorized roleList={ROLES_TRUPACTA_TEAM_VIEW}>
            <Col span={12}>
              <Tooltip title="View Contract">
                <span
                  className="icon-row-view clr-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(setRedirectStatus(true, "contract"));
                    if (row.status == "pending") {
                      onOpenSelectedContract(row);
                    } else {
                      history.push(
                        EDIT_TEMPLATED_CONTRACT.replace(":id", row.id)
                      );
                    }
                  }}
                ></span>
              </Tooltip>
            </Col>
          </Authorized>
          <Authorized
            roleList={[
              ...ROLES_CONTRACTS_INVOICE_MANANGER,
              ...ROLES_CONTRACT_UPLOAD_MANAGER,
            ]}
            shouldNotIncludePersona={ROLES_TRUPACTA_TEAM_VIEW}
          >
            {row.status === Status.PROCESSED && (
              <Col span={12}>
                <Tooltip title="Edit Contract">
                  <span
                    className="icon-edit clr-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setRedirectStatus(true, "contract"));
                      history.push(
                        VIEW_TEMPLATED_CONTRACT.replace(":id", row.id)
                      );
                    }}
                  ></span>
                </Tooltip>
              </Col>
            )}
          </Authorized>
          {row.status === Status.PENDING && (
            <Col span={12}>
              <Tooltip title="Delete contract">
                <DeleteOutlined
                  style={{ cursor: "pointer" }}
                  className="clr-link"
                  onClick={() =>
                    setIsOpenModal({
                      state: true,
                      id: row.id,
                    })
                  }
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
    },
  ];
  const onTabChange = (pageNo, pageSize) => {
    setPage(pageNo);
    setUrlParams({
      ...urlparams,
      limit: pageSize,
      skip: pageNo * pageSize - pageSize,
    });
  };

  const onClearFilters = () => {
    setClearField(true);
    setPage(1);
    setUrlParams({
      ...urlparams,
      skip: 0,
      searchText: "",
      status: "",
      startDate: "",
      endDate: "",
      vendor: null,
    });
  };
  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };
  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen.state}
          handleOk={() => onRemove(isModalOpen.id)}
          setParentClose={() => setIsOpenModal({ state: false, id: null })}
        >
          Are you sure you want to delete this contract?
        </Modal>
      )}
      <Row className="content-header">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Contracts</h2>
        </Col>
        <Authorized roleList={[...ROLES_CONTRACTS_ADD_VIEW, ...ROLES_CONTRACT_UPLOAD_MANAGER]}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex f-justify-end">
            <Link to={UPLOAD_CONTRACT_ROUTE}>
              <Button className="btn-primary">
                {' '}
                <span className="btn-icon icon-upload"></span>Add New
              </Button>
            </Link>
          </Col>
        </Authorized>
      </Row>
      <Row className="filter-wrapper" gutter={[24, 12]} align="top">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6}>
          <Input
            placeholder="Search..."
            name="searchText"
            onChange={(e) => {
              setUrlParams({
                ...urlparams,
                skip: 0,
                searchText: e.target.value,
              });
              setPage(1);
            }}
            value={urlparams.searchText}
            prefix={<span class="icon-search"></span>}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={3}>
          <VendorDropdown
            defaultValue={selVendor}
            id={selVendor}
            vendor={vendor}
            getValue={(vendor) => {
              setUrlParams({
                ...urlparams,
                skip: 0,
                vendor: vendor.key,
              });
              setPage(1);
            }}
            clearField={clearField}
            setClearField={() => setClearField(false)}
          />
        </Col>
        <Col xs={12} sm={12} md={24} lg={12} xl={12} xxl={7}>
          <DatePicker.RangePicker
            placeholder={['YYYY-MM-DD', 'YYYY-MM-DD']}
            name="dateRange"
            allowClear={false}
            suffixIcon={<span class="icon-calendar"></span>}
            value={[
              urlparams.startDate ? moment(urlparams.startDate) : '',
              urlparams.endDate ? moment(urlparams.endDate) : '',
            ]}
            allowEmpty={[false, true]}
            onChange={(date) => {
              setUrlParams({
                ...urlparams,
                skip: 0,
                startDate: moment(date[0]).format('YYYY-MM-DD'),
                endDate: date[1] ? moment(date[1]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
              });
              setPage(1);
            }}
          />
        </Col>
        <Col xs={12} sm={12} md={18} lg={8} xl={8} xxl={5}>
          <Form>
            <Radio.Group
              name="status"
              value={urlparams.status === '' ? 'all' : urlparams.status}
              buttonStyle="solid"
              className="filter-group-radio-solid-t1"
              size="large"
              onChange={(e) => {
                setUrlParams({
                  ...urlparams,
                  skip: 0,

                  status: e.target.value === 'all' ? '' : e.target.value,
                });
                setPage(1);
              }}
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value={Status.PROCESSED}>Processed</Radio.Button>
              <Radio.Button value={Status.PENDING}>Pending</Radio.Button>
            </Radio.Group>
          </Form>
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
          <Button type="link" className="btb-clear-filter" onClick={onClearFilters}>
            <span class="btn-icon icon-cross"></span> Clear Filters
          </Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={contract.contracts}
          pagination={{
            defaultPageSize: urlparams.limit,
            position: ['none', 'bottomCenter'],
            total: contract.count,
            pageSizeOptions: ['10', '15', '20'],
            locale: { items_per_page: '' },
            showSizeChanger: true,
            showTotal: () => `Showing ${urlparams.skip + 1} - ${urlparams.limit * page} of ${contract.count} records.`,
            current: page,
            onChange: (page, pageSize) => onTabChange(page, pageSize),
          }}
        />
      </Spin>
    </>
  );
};

export default ContractList;
