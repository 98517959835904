import React, { useEffect, useState } from "react";
import _ from "lodash";
import Meter from '../../Common/Charts/Meter';
import { Row, Col, Switch, Divider, Typography } from 'antd';
//import Loader from "../Loader";

const VendorScore = ({ scores, vendorId }) => {
  const [data, setData] = useState([]);
  const { Title } = Typography;

  useEffect(() => {
    if (scores && scores.length > 0) {
      setData(
        scores.sort((v1, v2) => (v1.vendor_id == vendorId ? -1 : v2.vendor_id == vendorId ? 1 : v2.total - v1.total)),
      );
    }
  }, [scores, vendorId]);

  return (
    <>
      <Row gutter={[0, 24]} className="db-row" justify="start">
        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
          <Title level={5}>Vendor Score Index</Title>
        </Col>
        <Col xs={24} sm={8} md={18} lg={8} xl={8} className="align-right">
          <Switch
            defaultChecked
            checkedChildren="Top 5"
            unCheckedChildren="Bottom 5"
            onChange={(val) => {
              if (val) {
                setData([
                  ...scores.sort((v1, v2) =>
                    v1.vendor_id == vendorId ? -1 : v2.vendor_id == vendorId ? 1 : v2.total - v1.total,
                  ),
                ]);
              } else {
                setData([
                  ...scores.sort((v1, v2) =>
                    v1.vendor_id == vendorId ? -1 : v2.vendor_id == vendorId ? 1 : v1.total - v2.total,
                  ),
                ]);
              }
            }}
          />
        </Col>
      </Row>

      <Row gutter={[0, 0]} className="" justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {data.map((s, i) => {
            return (
              i < 5 && (
                <>
                  <Meter title={s.vendor_name} score={s} />
                  <Divider />
                </>
              )
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default VendorScore;
