import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Card, Badge, Row, Col, Typography } from 'antd';
import { RadialBar, Bar } from '@ant-design/charts';
import Loader from "../Loader";
import { convertToInternationalCurrencySystem } from '../../Components/Dashboard/HelperFunctions';

const RadialChart = ({ labelText = '', chartData }) => {
  const [data, setData] = useState([]);
  const { Title } = Typography;
  const [ duration, setDuration ] = useState('12')

  useEffect(() => {
    if (chartData && chartData.poAmount) {
      setData(chartData.poAmount);
    }
  }, [chartData]);

  useEffect(() => {
    if (chartData && chartData.poAmount && chartData.poAmountSixMonths) {
      setData(duration === '12' ? chartData.poAmount : chartData.poAmountSixMonths);
    }
  }, [duration]);

  const config = {
    data,
    height: 250,
    width: 280,
    xField: 'vendor_name',
    yField: 'count',
    maxAngle: 270,
    innerRadius: 0.2,
    tooltip: {
      formatter: (datum) => {
        return {
          name: 'Total',
          value: convertToInternationalCurrencySystem(datum.count),
        };
      },
    },
    color: ['#fdede8', '#fadbd1', '#fdb19b', '#fb7d59', '#e85320'],
    colorField: 'vendor_name',
  };

  const config2 = {
    data,
    xField: 'count',
    yField: 'vendor_name',
    maxBarWidth: 15,
    height: 300,
    seriesField: 'count',
    legend: false,
    //legend: {
    //position: 'top-left',
    //},
    color: ['#addcde', '#83cbcd', '#5ababd', '#31a8ac', '#08979c'],
    tooltip: {
      formatter: (datum) => {
        return {
          name: 'Spend',
          value: convertToInternationalCurrencySystem(datum.count),
        };
      },
    },
  };

  return (
    <>
      <Row className="mb-1">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={5}>{labelText}</Title>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <div className="db-tabs-buttons-wrapper-t2">
            <span
              onClick={() => {
                setDuration('12');
              }}
              className={duration === '12' ? 'activateLink ml-1-im' : 'ml-1-im'}
            >
              12 months
            </span>
            <span
              onClick={() => {
                setDuration('6');
              }}
              className={duration === '6' ? 'activateLink' : ''}
            >
              6 months
            </span>
          </div>
        </Col>
      </Row>
      {chartData && !_.isEmpty(data) ? <Bar {...config2} /> : <Loader />}
    </>
  );
};

export default RadialChart;
