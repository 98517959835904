const accountEnpoints = {
  LOGIN: "/login",
  FORGOT_PASSOWRD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  DEMO_APP_FORM: "/demo-request",
  SESSION: "/session"
};

const userEndpoints = {
  GET_USER: "/users/me",
  UPDATE_USER: "/users/me",
  GET_ALL_USERS: "/users",
  GET_USERS_WRT_ORG: "/users/organization"
};

const dashboard = {
  GET_TOTAL_COUNT: '/dashboard/counts',
  GET_CONTRACT_RENEWAL: '/dashboard/contracts',
  GET_DISCREPENCY: '/dashboard/discrepency',
  GET_PROCESSING_TREND: '/dashboard/trend',
  GET_TOP_5_SKU: '/dashboard/skus',
  GET_TOP_5_VENDOR: '/dashboard/vendors',
  GET_VENDOR_SCORE: '/dashboard/vendorsScore',
  CONTRACT_COUNT: 'dashboard/counts/contracts/organization',
  CONTRACT_AND_INVOICE_COUNT: 'dashboard/counts/contractsAndInvoices/organization',
  RECENT_CONTRACTS_AND_INVOICES: 'dashboard/recent/contractsAndInvoices/organization',
  RECENT_CONTRACTS: '/dashboard/recent/contracts/organization',
  GET_WEEKLY_CONTRACTS: '/dashboard/contracts/weekly/organization',
  GET_WEEKLY_CONTRACTS_AND_INVOICES: '/dashboard/contractsAndInvoices/weekly/organization',
  GET_RECENT_MONTHLY_CONTRACTS: '/dashboard/contracts/monthly/organization',
  GET_PENDING_CONTRACTS: '/dashboard/contracts/pending/organization',
  GET_PROCESSED_CONTRACTS: '/dashboard/contracts/processed/organization',
  GET_PO_COUNT: '/dashboard/purchaseOrderCount/organization',
  GET_WEEKLY_PO: '/dashboard/pos/weekly/organization',
  GET_PO_WITH_DISC_APPROVAL: '/dashboard/pos/discrepancyApproval/organization',
  GET_PO_WITH_REJECTED: '/dashboard/pos/rejected/organization',
  GET_RECENT_PO: '/dashboard/recent/pos/organization',
  GET_PENDING_APPROVAL_PO_INVOICE: '/dashboard/counts/pendingApproval/posAndInvoices/organization',
  GET_WEEKLY_PO_INVOICES: '/dashboard/weekly/posAndInvoices/organization',
  GET_RECENT_CONTRACTS_PO_INVOICES: '/dashboard/recent/contractsAndPosAndInvoices/organization',
  GET_WEEKLY_CONTRACT_PO_INVOICE: '/dashboard/weekly/contractsAndPosAndInvoices/organization/',
  GET_INVOICE_COUNT: '/dashboard/invoiceCount/organization',
  GET_WEEKLY_INVOICE: '/dashboard/invoices/weekly/organization',
  GET_INVOICE_WITH_DISC_APPROVAL: '/dashboard/invoices/discrepancyApproval/organization',
  GET_INVOICE_WITH_REJECTED: '/dashboard/invoices/rejected/organization',
  GET_RECENT_INVOICE: '/dashboard/recent/invoices/organization',
  GET_BACKEND_COMMIT_VERSION: '/dashboard/recent/commit',
  GET_CURRENT_MONTH_CONTRACTS_POS_INVOICES: '/dashboard/monthly/contractsAndPosAndInvoices/organization',
};
const vendorEnpoints = {
  GET_ALL_VENDORS: "/vendors/getAll",
  GET_VENDOR_LIST_WRT_ORG: "/vendors/getAll/",
};

const contractEndpoints = {
  CREATE_CONTRACTS: "/contracts/create",
  GET_CONTRACTS: "/contracts/getAll/",
  DELET_CONTRACTS: "/contracts",
  GET_CONTRACTS_BY_ID: "/contracts",
  UPDATE_TEMPLATED_CONTRACT: "/templatedContracts/update",
  CREATE_TEMPLATED_CONTRACTS: "/templatedContracts/addNew",
  GET_SELECTED_TEMPLATE_FILE: "/documents/contracts/file"
}

const invoiceEndpoints = {
  CREATE_INVOICES: "/invoices/create",
  GET_INVOICES_WITH_DISC: "/discrepancies/organization/",
  GET_ALL_INVOICES: "/invoices/organization/",
  GET_INVOICE_BY_ID: "/invoices/",
  UPDATE_INVOICE_BY_ID: "/invoices/update/",
  UPDATE_INVOICE_AND_INVOICE_ITEMS_BY_ID: "/invoices/invoiceItems/update/",
  DELET_INVOICE: "/invoices/",
  RECHECK_FOR_INVOICE_DISCREPANCY: "/invoice/item/discrepancy/",
  UPDATE_INVOICE_COMMENT_STATUS: "/invoice/statusChangeWithComment/",
  GET_INVOICE_LIST_FOR_MANAGER: "/invoices/discrepancyApprover/organization/",
  GET_INVOICE_FILE_URL: "/documents/invoice/file/"
  
};

const documentEndpoints = {
  UPLOAD_CONTRACTS_DOCS: "documents/contract/upload",
  UPLOAD_INVOICES_DOCS: "/documents/invoice/upload",
  UPLOAD_PO_DOCS: "/documents/po/upload",
  GET_CONTRACT_FILE: "/documents/contracts/file/"
};

const poEndpoints = {
  CREATE_PURCHASE_ORDER: "/po/create",
  GET_POS_WITH_DISC: "/discrepancies/organization/",
  VIEW_PURCHASE_ORDERS: "/po/organization/",
  GET_PO_BY_ID: "/po/",
  UPDATE_PO_BY_ID: "/po/",
  DELETE_PO: "/po/",
  CREATE_PO_DISCREPANCY: "/discrepancies/create",
  CREATE_PO_MANUALLY: "/po/create/manually",
  UPDATE_PO_WITH_PO_ITEMS_BY_ID: "/po/poItems/update/",
  RECHECK_FOR_PO_DISCREPANCY: "/po/item/discrepancy/",
  UPDATE_PO_COMMENT_STATUS: "/po/statusChangeWithComment/",
  GET_PO_FOR_MANAGER: "/po/discrepancyApprover/organization/",
  GET_PO_FILE: "/documents/po/file/"
};

const OrganizationEnpoints = {
  GET_ORGANIZATIONS_LIST: "/organizations/getAll",
};

const templates = {
  GET_ALL_TEMPLATES: "/templates/getAll",
  GET_TEMPLATE: "/templates/",
  GET_TEMPLATE_BY_CONTRACT_ID: "/templatedContracts/contract/"
}

const comments = {
  CREATE_COMMENT: "/history/create",
  GET_ENTITY_COMMENTS: "/history/all/organization",
  GET_COMMENTS_REASONS: "/commentReason/all/organization"
}

const reports = {
  GET_PO_INVOICE_WITH_STATUS: "/reports/countWithStatus",
  GET_PROCESSED_PO_AND_INVOICES: "/reports/processed",
  GET_APPROVED_REJECTED_PO_INVOICE: "/reports/processedAndRejected",
  GET_PO_COUNT_WITH_STATUS: "/reports/po/countWithStatus",
  GET_INVOICE_COUNT_WITH_STATUS: "/reports/invoice/countWithStatus",
  GET_PROCESSED_PO: "/reports/po/processed",
  GET_PROCESSED_INVOICES: "/reports/invoice/processed",
  GET_ALL_CONTRACTS: "/reports/contracts",
  GET_PROCESSED_CONTRACTS_WITH_TIME: "/reports/contracts/processedWithTime",
  GET_LOGIN_FREQUENCY: "/reports/loginFrequency",
  GET_PROCESSED_AND_REJECTED_POS: "/reports/po/processedAndRejected",
  GET_PROCESSED_AND_REJECTED_INVOICES: "/reports/invoice/processedAndRejected",
  GET_AGGREGATE_DISCREPANCIES: "/reports/aggregatedDiscrepancy"

}
export default {
  ...accountEnpoints,
  ...userEndpoints,
  ...dashboard,
  ...vendorEnpoints,
  ...contractEndpoints,
  ...invoiceEndpoints,
  ...documentEndpoints,
  ...poEndpoints,
  ...templates,
  ...OrganizationEnpoints,
  ...comments,
  ...reports
};
