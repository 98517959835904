import { act } from "react-dom/test-utils";
import * as ActionTypes from "../actions/Types";

const initialState = {
  contractsCount: {},
  invoicesCount: {},
  recentUploads: {},
  recentContractsWrtStatus: {},
  weeklyContracts: [],
  weeklyContractsAndInvoices: {},
  monthlyContracts: [],
  pendingContracts: [],
  processedContracts: [],
  poCount: {},
  weeklyPO: [],
  discApprovalPO: [],
  rejectedPO: [],
  recentPO: [],
  pendingApprovalPOAndInvoices: [],
  weeklyPOAndInvoice: [],
  recentContractAndInvoiceAndPO: [],
  weeklyContractPOInvoices: [],
  // weeklyInvoices: []
  invoiceCount: {},
  weeklyInvoice: [],
  discApprovalInvoice: [],
  rejectedInvoice: [],
  recentInvoice: [],
  monthlyContractsPOsInvoices: [],
  backendCommitVersion: "",
};

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTRACT_AND_INVOICE_COUNT:
      return {
        ...state,
        contractsCount: action.payload.contracts,
        invoicesCount: action.payload.invoices,
      };
    case ActionTypes.GET_RECENT_CONTRACTS_AND_INVOICES:
      return {
        ...state,
        recentUploads: {
          contracts: action.payload.contracts,
          invoices: action.payload.invoices,
        },
        recentContracts: { contracts: action.payload.contracts },
        recentInvoices: { invoices: action.payload.invoices },
      };
    case ActionTypes.GET_RECENT_CONTRACTS:
      return {
        ...state,
        recentContractsWrtStatus: {
          pendingContracts: action.payload.pending,
          processedContracts: action.payload.processed,
        },
        recentPendingContracts: { pendingContracts: action.payload.pending },
        recentProcessedContracts: {
          processedContracts: action.payload.processed,
        },
      };

    case ActionTypes.GET_DISCREPENCY:
      return { ...state, discrepency: action.payload };
    case ActionTypes.GET_PROCESSING_TREND:
      return { ...state, processingTrend: action.payload };
    case ActionTypes.GET_TOP_5_SKU:
      return { ...state, topSku: action.payload };
    case ActionTypes.GET_TOP_5_VENDOR:
      return { ...state, topVendor: action.payload };
    case ActionTypes.GET_VENDOR_SCORE: 
      return { ...state, vendorScore: action.payload };
    case ActionTypes.GET_TOTAL_COUNT:
      return { ...state, totalCount: action.payload };
    case ActionTypes.GET_CONTRACT_RENEWAL:
      return { ...state, contractRenewal: action.payload };
    case ActionTypes.GET_WEEKLY_CONTRACTS:
      return { ...state, weeklyContracts: action.payload };
    case ActionTypes.GET_WEEKLY_CONTRACTS_AND_INVOICES:
      return { ...state, weeklyContractsAndInvoices: action.payload };
    case ActionTypes.GET_CONTRACTS_COUNT:
      return { ...state, contractsCount: action.payload };
    case ActionTypes.GET_RECENT_MONTHLY_CONTRACTS:
      return { ...state, monthlyContracts: action.payload };
    case ActionTypes.GET_PENDING_CONTRACTS:
      return { ...state, pendingContracts: action.payload };
    case ActionTypes.GET_PROCESSED_CONTRACTS:
      return { ...state, processedContracts: action.payload };
    case ActionTypes.GET_PO_COUNTS:
      return { ...state, poCount: action.payload };
    case ActionTypes.GET_WEEKLY_PO:
      return { ...state, weeklyPO: action.payload };
    case ActionTypes.GET_DISC_APPROVAL_PO:
      return { ...state, discApprovalPO: action.payload };
    case ActionTypes.GET_REJECTED_PO:
      return { ...state, rejectedPO: action.payload };
    case ActionTypes.GET_RECENT_PO:
      return { ...state, recentPO: action.payload };
    case ActionTypes.GET_PO_INVOICE_APPROVAL_COUNTS:
      return { ...state, pendingApprovalPOAndInvoices: action.payload };
    case ActionTypes.GET_WEEKLY_PO_INVOICES:
      return { ...state, weeklyPOAndInvoice: action.payload };
    case ActionTypes.GET_RECENT_ENTITY_RECORDS:
      return { ...state, recentContractAndInvoiceAndPO: action.payload };
    case ActionTypes.GET_WEEKLY_PO_CONTRACT_INVOICES:
      return { ...state, weeklyContractPOInvoices: action.payload };
    case ActionTypes.GET_CURRENT_MONTH_PO_CONTRACT_INVOICES:
      return { ...state, monthlyContractsPOsInvoices: action.payload };

    /// Invoice Manager ///
    case ActionTypes.GET_INVOICE_COUNTS:
      return { ...state, invoiceCount: action.payload };
    case ActionTypes.GET_WEEKLY_INVOICE:
      return { ...state, weeklyInvoice: action.payload };
    case ActionTypes.GET_DISC_APPROVAL_INVOICE:
      return { ...state, discApprovalInvoice: action.payload };
    case ActionTypes.GET_REJECTED_INVOICE:
      return { ...state, rejectedInvoice: action.payload };
    case ActionTypes.GET_RECENT_INVOICE:
      return { ...state, recentInvoice: action.payload };

    /// Commit Version ///
    case ActionTypes.GET_COMMIT_VERSION:
      return { ...state, backendCommitVersion: action.payload };
    default:
      return state;
  }
};
