import {
  ROLES_REPORT_VIEW,
  ROLES_TRUPACTA_TEAM_VIEW,
  ROLES_DISCREPANCY_APPROVER_VIEW,
} from "../utils/constants";
import {
  PoInvoiceCountWithStatusColumns,
  PoInvoiceProcessedColumns,
  ContractsProcessedWithTimeDiffCols,
  userLoginFrequencyColumns,
  discrepancyAggregatePO,
  discrepancyAggregateInvoice,
  approvedRejectedPoInvoicePercentCols,
} from "../Reports/ReportColumns";

import {
  defaultCounterFunc,
  getApprovedRejectedAvg,
  getFormatDataForNumberCount,
  getFormatDataForCostSaving,
  getCounterFieldsForPOAggregate,
  getCounterFieldsForInvoiceAggregate,
  getFormatDataForAggregatePercentage,
  getFormatDataForSumofTime
} from "../Components/Dashboard/HelperFunctions";

const reportTemplates = [
  {
    id: 1,
    title: 'Discrepancy Amount Aggregate for PO',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'VENDOR'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'DISCREPANCY_AGGREGATE_PO',
    columns: discrepancyAggregatePO,
    counterFields: getCounterFieldsForPOAggregate,
    graphs: [
      {
        dataFunction: getFormatDataForCostSaving,
        xField: 'month',
        yField: 'amount',
        interval: 50000,
        labelText: 'Discrepancy Amount for Purchase Orders',
        formatter: '$',
      },
      {
        dataFunction: getFormatDataForNumberCount,
        xField: 'month',
        yField: 'number',
        interval: 5,
        labelText: 'Discrepancy Count for Purchase Orders',
      },
    ],
  },
  {
    id: 2,
    title: 'Discrepancy Amount Aggregate for Invoice',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'VENDOR'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'DISCREPANCY_AGGREGATE_INVOICE',
    columns: discrepancyAggregateInvoice,
    counterFields: getCounterFieldsForInvoiceAggregate,
    countTitle: 'Processed documents',
    graphs: [
      {
        dataFunction: getFormatDataForCostSaving,
        xField: 'month',
        yField: 'amount',
        interval: 50000,
        labelText: 'Discrepancy Amount for Invoices',
        formatter: '$',
      },
      {
        dataFunction: getFormatDataForNumberCount,
        xField: 'month',
        yField: 'number',
        interval: 5,
        labelText: 'Discrepancy Count for Invoices',
      },
    ],
  },
  {
    id: 3,
    title: 'Approved/Rejected PO and Invoice percentages',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'VENDOR', 'DOC_TYPE'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'APPROVED_REJECTED_PO_INVOICE_PERCENTAGE',
    columns: approvedRejectedPoInvoicePercentCols,
    counterFields: getApprovedRejectedAvg,
    countTitle: 'Processed documents',
    graphs: [
      {
        dataFunction: getFormatDataForAggregatePercentage,
        xField: 'month',
        yField: 'number',
        interval: 5,
        labelText: 'Approved/Rejected Threshold',
      },
    ],
  },
  {
    id: 4,
    title: 'Processed Purchase orders And Invoices',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'VENDOR', 'DOC_TYPE'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'PROCESSED_PO_INVOICE_WITH_COUNT',
    columns: PoInvoiceProcessedColumns,
    counterFields: defaultCounterFunc,
    countTitle: 'Processed documents',
    graphs: [
      {
        dataFunction: getFormatDataForNumberCount,
        xField: 'month',
        yField: 'number',
        interval: 10,
        labelText: 'Processed Documents',
      },
    ],
  },
  {
    id: 5,
    title: 'Template not found for PO and Invoices',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'VENDOR', 'DOC_TYPE'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'PO_INVOICE_COUNT_WITH_STATUS_REPORT',
    columns: PoInvoiceCountWithStatusColumns,
    counterFields: defaultCounterFunc,
    countTitle: 'Times Template not found',
    graphs: [
      {
        dataFunction: getFormatDataForNumberCount,
        xField: 'month',
        yField: 'number',
        interval: 5,
        labelText: 'No of times Template not found',
      },
    ],
  },
  {
    id: 6,
    title: 'Processed Contracts',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'VENDOR'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'PROCESSED_CONTRACTS_WITH_TIME_DIFFERENCE',
    columns: ContractsProcessedWithTimeDiffCols,
    counterFields: defaultCounterFunc,
    countTitle: 'Processed Contracts',
    graphs: [
      {
        dataFunction: getFormatDataForNumberCount,
        xField: 'month',
        yField: 'number',
        interval: 10,
        labelText: '# of Processed Contracts',
      },
      {
        dataFunction: getFormatDataForSumofTime,
        xField: 'month',
        yField: 'totalProcessedTime',
        interval: 20,
        labelText: 'Processing time',
        // formatter: " hrs",
      },
    ],
  },
  {
    id: 7,
    title: 'User log In - Frequency',
    filters: ['RANGE_PICKER', 'ORGANIZATION', 'USER'],
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_TRUPACTA_TEAM_VIEW],
    label: 'LOG_IN_FREQUENCY',
    columns: userLoginFrequencyColumns,
    counterFields: defaultCounterFunc,
    countTitle: 'Number of Login Attempts',
    showUsers: true,
    graphs: [
      {
        dataFunction: getFormatDataForNumberCount,
        xField: 'month',
        yField: 'number',
        interval: 10,
        labelText: 'User log in Frequency',
      },
    ],
  },
];

export default reportTemplates;
