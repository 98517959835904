import * as ActionTypes from "../actions/Types";

const initialState = {
    vendors: []
};

export const vendor = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_VENDOR_LIST:
      return { ...state, vendors: action.payload};
    default:
      return state;
  }
};
