import * as ActionTypes from "../actions/Types";

const initialState = {
  invoice: {},
  count: 0,
  invoices: [],
  invoiceFilrUrl: null,
};

export const invoice = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.VIEW_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        count: action.payload.count,
      };
    case ActionTypes.VIEW_INVOICE:
      return { ...state, invoice: action.payload };
    case ActionTypes.UPDATE_INVOICE:
      return { ...state, invoice: action.payload };
    case ActionTypes.DELETE_INVOICE:
      return { ...state, count: state.count - 1 };
    case ActionTypes.GET_INVOICE_FILE:
      return {
        ...state,
        invoiceFileUrl: action.payload,
      };
    case ActionTypes.CLEAR_INVOICE_FILE_URL:
      return {
        ...state,
        invoiceFileUrl: null,
      };
    default:
      return state;
  }
};
