// import AWS from "mock-aws-s3";

import axios from "axios";
import { HIDE_FILE_LOADER } from "../actions/Types";
import { ConfigureStore } from "../store";

export const uploadToS3 = (url, file) => {
  return new Promise((res, rej) => {
    let formData = new FormData();
    formData.append("file", file);
    axios
      .put(url, formData)
      .then((d) => {
        res(d);
      })
      .catch((ex) => {
        rej(ex);
      });
  });
};

export const openPDF = async (url) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });
    ConfigureStore.dispatch({ type: HIDE_FILE_LOADER });
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  } catch (error) {
    return { error };
  }
};
