import * as ActionTypes from "../actions/Types";

const initialState = {
  organizationsList: [],
};

export const organizations = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORGANIZATIONS_LIST:
      return { ...state, organizationsList: action.payload };
    default:
      return state;
  }
};
