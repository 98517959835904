import React, { useState } from "react";
import { Row, Col, Button, Image } from "antd";
import NoFilesChoosen from "../../Common/NoFilesChoosen/NoFilesChoosen";
import UploadPOList from "../../Components/PO/UploadPOList";
import Uploader from "../../Common/Uploader";
import IconUploadManually from "../../assets/images/bg-icon-uplaod-manually.png";
import history from "../../History/history";
import { ADD_PO_MANUALLY } from "../../Constants/routeNames";

const UploadPO = () => {
  const [files, setFiles] = useState([]);
  const acceptedFormats = ["pdf"];
  const setFilteredFiles = (files) => {
    setFiles(
      files.filter((file) =>
        acceptedFormats.includes(file.name.split(".").reverse()[0])
      )
    );
  };
  const onRemoveFile = (key) => {
    setFiles(files.filter((file, index) => index !== key));
  };
  return (
    <div>
      <Row className="content-header">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Add Purchase Order</h3>

          <Row gutter={[24, 12]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={17}>
              <Uploader
                files={files}
                setFiles={(files) => setFilteredFiles(files)}
                type="Purchase order files."
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={7}>
              <div className="add-manually-wrapper">
                <Image src={IconUploadManually} alt="" preview={false} />
                <p className="label-add-head">Add PO Manually</p>
                <p className="label-add-des"></p>
                <Button
                  className="btn-tertiary"
                  onClick={() => history.push(ADD_PO_MANUALLY)}
                >
                  <span className="btn-icon icon-add-new"></span> Add Manually
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {files.length > 0 ? (
        <UploadPOList files={files} removeFile={onRemoveFile} />
      ) : (
        <NoFilesChoosen />
      )}
    </div>
  );
};

export default UploadPO;
