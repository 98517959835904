import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from "lodash";
import { Badge, Card, Row, Col } from "antd";
import { Pie } from "@ant-design/charts";
import Loader from "../../Common/Loader";
import { convertToInternationalCurrencySystem } from '../../Components/Dashboard/HelperFunctions';

const PieChart = ({ uploadInfo, labelText, colors }) => {
  const history = useHistory();
  const selectedTheme = useSelector((state) => state.header.theme);
  const [legendColor, setLegendColor] = useState('black'); 

  const [dataInfo, setDataInfo] = useState([ { data: [] }]);
  
  const [selectedOpt, setSelectedOpt] = useState('');

  useEffect(() => {
    if(selectedTheme) {
      setLegendColor(selectedTheme === 'dark-theme' ? 'white' : 'black');
    } else {
      setLegendColor(localStorage.getItem('THEME') === 'dark-theme' ? 'white' : 'black');
    }
  }, [selectedTheme]);
  
  useEffect(() => {
    if(uploadInfo) {
      setDataInfo(uploadInfo[0]);
      setSelectedOpt(uploadInfo[0].type);
    }
  }, [uploadInfo]);

  var config = {
    data: dataInfo.data,
    angleField: 'value',
    colorField: 'type',
    innerRadius: 0.7,
    color: colors,
    height: 300,
    radius: 200,
    maxColumnWidth: 25,
    legend: {
      position: 'bottom',
      itemName: {
        style: {
          fill: legendColor,
        },
        formatter: (field, d, i) => {
          return (
            field +
            ': ' +
            (selectedOpt === 'Amount'
              ? convertToInternationalCurrencySystem(dataInfo.data[i].value)
              : dataInfo.data[i].value)
          );
        },
      },
      label: {
        formatter: (v) => {
          console.log(v);
        },
      },
      //formatter: (name) => name,
    },
    label: {
      labelLine: null,
      autoHide: true,
      style: {
        opacity: 0,
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
        },
        customHtml: () => {
          const total =
            selectedOpt === 'Amount' ? convertToInternationalCurrencySystem(dataInfo.total) : dataInfo.total;
          return (
            <div className="pie-font">
              <span className="pie-label">Total</span>
              <br></br>
              <span className="pie-numbers">{total ? total : 0}</span>
            </div>
          );
        },
      },
    },
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        if (args && args.length > 0) {
          const plotData = args[0].data.data;
          const vendorUrl = plotData.vendor === '0' ? '' : `&vendor=${plotData.vendor}`;
          const url =
            plotData.type === 'Invoice' ? `/invoices/view?filter=year${vendorUrl}` : `/po/view?filter=year${vendorUrl}`;
          history.push(url);
        }
      });
    },
  };
  return (
    <Card className="dashboad-chart-box dashboard-card-t1">
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="db-row">
        <h3>{labelText}</h3>

        {uploadInfo && uploadInfo[0].type && (
          <div className="db-tabs-buttons-wrapper-t2">
            {uploadInfo.map((info, i) => (
              <span
                onClick={() => {
                  setDataInfo(uploadInfo[i]);
                  setSelectedOpt(uploadInfo[i].type);
                }}
                className={selectedOpt === info.type ? 'activateLink' : ''}
              >
                {info.type}
              </span>
            ))}
          </div>
        )}
      </div>

      {uploadInfo && !_.isEmpty(dataInfo.data) ? <Pie {...config} /> : <Loader />}

      {/* 
      <Row className="legend-wrapper" gutter={[18, 12]}>
        {dataInfo &&
          dataInfo.data &&
          dataInfo.data.map((d, i) => (
            <Col xs={24} sm={24} md={24} lg={24} xl={12} className="db-legend-t2">
              <span className="inner-box">
                <Badge color={colors[i]} text={d.type + ':'} />
              </span>
              <span className="ant-badge-vale-text">{selectedOpt === 'Amount' ? Math.round(d.value) / 1000 + 'k' : d.value}</span>
            </Col>
          ))}
      </Row>
      */}
    </Card>
  );
};

export default PieChart;
