import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import _ from "lodash";
import { Card, Badge, Row, Col } from "antd";
import { Line } from '@ant-design/charts';
import Loader from "../Loader";

const LineChart = ({
  labelText = "",
  chartData
}) => {
  const [data, setData] = useState([]);
  const selectedTheme = useSelector((state) => state.header.theme);
  const [legendColor, setLegendColor] = useState('black'); 

  useEffect(() => {
    if (selectedTheme) {
      setLegendColor(selectedTheme === 'dark-theme' ? 'white' : 'black');
    } else {
      setLegendColor(localStorage.getItem('THEME') === 'dark-theme' ? 'white' : 'black');
    }
  }, [selectedTheme]);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    }
  }, [chartData]);

  const config = {
    data,
    height: 300,
    xField: 'month',
    yField: 'count',
    seriesField: 'category',
    padding: 'auto',
    smooth: true,
    legend: {
      itemName: {
        style: {
          fill: legendColor,
        },
      },
    },
    xAxis: {
      //type: 'count',
      tickCount: 5,
    },
    color: ['#08979c', '#13c2c2', '#e85320'],
  };

  return (
    <Card className="dashboad-chart-box dashboard-card-t1 ">
      <Row className="mb-1">
        <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={10}>
          <h3>{labelText} </h3>
        </Col>
      </Row>
      {chartData && !_.isEmpty(data) ? <Line {...config} /> : <Loader />}
    </Card>
  );
};

export default LineChart;
