import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Badge,
  Button,
  DatePicker,
  Input,
  Select,
  Tooltip,
  Spin,
} from "antd";
import { Link, useLocation } from 'react-router-dom';
import { DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import Notification from "../../Common/Notification";
import Modal from "../../Common/Modal";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import {
  actionDispatch,
  getInvoices,
  deleteInvoice,
  getVendors,
  setPagination,
  setRedirectStatus,
  getComments,
} from "../../actions/Creators";
import {
  ADD_INVOICE_ROUTE,
  UPLOAD_INVOICE_ROUTE,
  INVOICE_DISCREPANCY_APPROVER_ROUTE,
} from "../../Constants/routeNames";
import { Status, badgeColor } from "./InvoiceStatus";
import Authorized from "../../Role-Authorization/Authorized";
import {
  ROLES_INVOICE_ADD_VIEW,
  ROLES_DISCREPANCY_APPROVER_VIEW,
  ROLES_INVOICE_MANAGER,
} from "../../utils/constants";
import CommentsData from "../../Common/CommentsCard";
import { isAuthorized } from "../../Services/Auth/auth";
import { dateToLocal } from "../../utils/moment";

const InvoiceList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pagination = useSelector((state) => state.pagination.invoice);
  const rolesArr = useSelector((state) => state.auth.roles);
  const comments = useSelector((state) => state.commentsHistory);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isPaginationSet, setIsPaginationSet] = useState(
    pagination.isRedirectedFromChild
  );
  const [page, setPage] = useState(
    pagination.page && isPaginationSet ? pagination.page : 1
  );
  const [params, setParams] = useState({
    limit: pagination.limit && isPaginationSet ? pagination.limit : 10,
    skip:
      pagination.page && isPaginationSet
        ? pagination.page * pagination.limit - pagination.limit
        : 0,
    searchText: "",
    minAmount: null,
    maxAmount: null,
    startDate: "",
    endDate: "",
    status: null,
  });
  const [isModalOpen, setIsOpenModal] = useState({
    state: false,
    id: null,
  });
  const invoice = useSelector((state) => state.invoice);
  const vendor = useSelector((state) => state.vendor);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );
  const { Option } = Select;

  useEffect(() => {
    dispatch(getVendors(organization.id));
  }, []);

  useEffect(() => {
    dispatch(setRedirectStatus(false, "invoice"));
    if (
      isAuthorized(
        ROLES_DISCREPANCY_APPROVER_VIEW,
        rolesArr,
        ROLES_INVOICE_ADD_VIEW[0]
      )
    ) {
      dispatch(getInvoices(organization.id, params, true));
    } else {
      dispatch(getInvoices(organization.id, params, false));
    }
    dispatch(
      setPagination(
        {
          page: page,
          limit: params.limit,
        },
        "invoice"
      )
    );
  }, [getInvoices, params, page]);

  useEffect(() => { 
    const queryParams = new URLSearchParams(location.search);
    const filter = queryParams.get('filter');
    if (filter) {
      const startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
      const endDate = moment(new Date()).format('YYYY-MM-DD');
      setParams({
        ...params,
        skip: 0,
        startDate,
        endDate
      });
      setPage(1);
    }
  }, []);

  const onChangeHandler = (e) => {
    setParams({
      ...params,
      skip: 0,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  const onClearFilters = () => {
    setPage(1);
    setParams({
      ...params,
      skip: 0,
      searchText: "",
      minAmount: null,
      maxAmount: null,
      startDate: "",
      endDate: "",
      status: null,
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "serialNumber",
      width: 60,
      render: (text, object, index) => (page - 1) * params.limit + (index + 1),
      align: "center",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_id",
      ellipsis: true,
      width: 150,
      render: (vendor_id) =>
        vendor &&
        vendor.vendors
          .filter((vendor) => vendor_id === vendor.id)
          .map((vendor) => vendor.title),
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      width: 120,
      render: (invoice_number) => (invoice_number ? invoice_number : "N/A"),
      ellipsis: true,
    },
    {
      title: "PO Associated",
      dataIndex: "associated_po",
      width: 120,
      render: (associated_po) => (associated_po ? associated_po : "N/A"),
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount) =>
        amount || amount == 0
          ? `$${amount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "N/A",
      width: 140,
    },
    {
      title: "Discrepancies",
      dataIndex: "discrepancyCount",
      render: (discrepancy) =>
        discrepancy
          ? discrepancy < 10
            ? `0${discrepancy}`
            : discrepancy
          : "N/A",
      width: 130,
      className: "bold-im",
    },
    {
      title: "Added On",
      width: 160,
      dataIndex: "createdAt",
      render: (date) => dateToLocal(date),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 240,
      render: (text) => {
        let color;
        for (let prop in badgeColor)
          if (prop === text) {
            color = badgeColor[prop];
          }
        return (
          <div>
            <Badge color={color} />
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "view",
      width: 126,
      align: "center",
      render: (text, row) => {
        return (
          <Row align="middle" justify="left">
            <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}>
              <Col span={8}>
                <Tooltip title="View Discrepancy Invoice">
                  <Link
                    className="clr-link "
                    to={`${INVOICE_DISCREPANCY_APPROVER_ROUTE}${row.id}`}
                  >
                    <span
                      className="icon-row-view"
                      onClick={() =>
                        dispatch(setRedirectStatus(true, "invoice"))
                      }
                    ></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
            <Authorized
              roleList={[...ROLES_INVOICE_ADD_VIEW, ...ROLES_INVOICE_MANAGER]}
            >
              <Col span={8}>
                <Tooltip title="View Invoice">
                  <Link
                    className="clr-link "
                    to={`${ADD_INVOICE_ROUTE}${row.id}`}
                  >
                    <span
                      className="icon-row-view"
                      onClick={() =>
                        dispatch(setRedirectStatus(true, "invoice"))
                      }
                    ></span>
                  </Link>
                </Tooltip>
              </Col>
            </Authorized>
            <Col span={8}>
              <Tooltip title="Show history">
                <HistoryOutlined
                  className="clr-link "
                  onClick={() => showComments(row.id)}
                />
              </Tooltip>
            </Col>
            {row.status === Status.PENDING && (
              <Col span={8}>
                <Tooltip title="Delete Invoice">
                  <DeleteOutlined
                    className="clr-link "
                    onClick={() =>
                      setIsOpenModal({
                        state: true,
                        id: row.id,
                      })
                    }
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const onTabChange = (pageNo, pageSize) => {
    setPage(pageNo);
    setParams({
      ...params,
      limit: pageSize,
      skip: pageNo * pageSize - pageSize,
    });
  };

  const notification = useSelector((state) => state.commonReducer.notification);
  const loading = useSelector((state) => state.commonReducer.loader);
  const onRemove = async (id) => {
    await dispatch(deleteInvoice(id, invoice.invoices));
    if (invoice.invoices.rows.length < 2) {
      setPage(page - 1);
      setParams({
        ...params,
        skip: (page - 1) * params.limit - params.limit,
      });
    } else dispatch(getInvoices(organization.id, params, false));
    setIsOpenModal({ state: false, id: null });
  };
  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };

  const showComments = (row_id) => {
    dispatch(
      getComments(organization.id, {
        entity_id: row_id,
        entity_type: "invoice",
      })
    );
    setIsCommentsOpen(true);
  };
  return (
    <>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen.state}
          handleOk={() => onRemove(isModalOpen.id)}
          setParentClose={() => setIsOpenModal({ state: false, id: null })}
        >
          Are you sure you want to delete this invoice?
        </Modal>
      )}
      {isCommentsOpen && (
        <Modal
          width={1400}
          loading={loading}
          details="Invoice history"
          showFooterButtons={false}
          isOpen={isCommentsOpen}
          setParentClose={() => setIsCommentsOpen(false)}
        >
          <CommentsData data={comments && comments.comments} />
        </Modal>
      )}
      <Row className="content-header">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Invoices</h2>
        </Col>
        <Authorized
          roleList={[...ROLES_INVOICE_ADD_VIEW, ...ROLES_INVOICE_MANAGER]}
        >
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex f-justify-end"
          >
            <Link to={UPLOAD_INVOICE_ROUTE}>
              <Button className="btn-primary">
                {" "}
                <span className="btn-icon icon-upload"></span>Add New
              </Button>
            </Link>
          </Col>
        </Authorized>
      </Row>
      <Row className="filter-wrapper" gutter={[24, 12]} align="top">
        <Col xs={12} sm={12} md={6} lg={10} xl={10} xxl={5}>
          <Input
            placeholder="Search..."
            name="searchText"
            onChange={onChangeHandler}
            value={params.searchText}
            prefix={<span class="icon-search"></span>}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={7} xl={7} xxl={3}>
          <Input
            placeholder="Min amount"
            name="minAmount"
            onChange={onChangeHandler}
            value={params.minAmount}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={7} xl={7} xxl={3}>
          <Input
            placeholder="Max amount"
            name="maxAmount"
            onChange={onChangeHandler}
            value={params.maxAmount}
          />
        </Col>
        <Col xs={12} sm={12} md={4} lg={10} xl={10} xxl={7}>
          <DatePicker.RangePicker
            placeholder={["YYYY-MM-DD", "YYYY-MM-DD"]}
            name="dateRange"
            allowClear={false}
            suffixIcon={<span class="icon-calendar"></span>}
            allowEmpty={[false, true]}
            onChange={(date) => {
              setParams({
                ...params,
                skip: 0,
                startDate: moment(date[0]).format("YYYY-MM-DD"),
                endDate: date[1]
                  ? moment(date[1]).format("YYYY-MM-DD")
                  : moment().format("YYYY-MM-DD"),
              });
              setPage(1);
            }}
            value={[
              params.startDate ? moment(params.startDate) : "",
              params.endDate ? moment(params.endDate) : "",
            ]}
          />
        </Col>
        <Authorized
          roleList={ROLES_DISCREPANCY_APPROVER_VIEW}
          shouldNotIncludePersona={ROLES_INVOICE_ADD_VIEW}
        >
          <Col xs={12} sm={12} md={4} lg={7} xl={7} xxl={3}>
            <Select
              onChange={(value) => {
                setParams({
                  ...params,
                  skip: 0,
                  status: value,
                });
                setPage(1);
              }}
              name="status"
              placeholder="All"
              value={params.status}
            >
              <Option value={Status.DISCREPANCY_APPROVAL}>
                Invoices W/ Disc
              </Option>
              <Option value={Status.APPROVED_WITH_DISCREPANCY}>
                Approved W/ Disc
              </Option>
              <Option value={Status.REJECTED}>Rejected</Option>
            </Select>
          </Col>
        </Authorized>
        <Authorized
          roleList={[...ROLES_INVOICE_ADD_VIEW, ...ROLES_INVOICE_MANAGER]}
        >
          <Col xs={12} sm={12} md={4} lg={7} xl={7} xxl={3}>
            <Select
              onChange={(value) => {
                setParams({
                  ...params,
                  skip: 0,
                  status: value,
                });
                setPage(1);
              }}
              name="status"
              placeholder="All"
              value={params.status}
            >
              <Option value={Status.PENDING}>Pending</Option>
              <Option value={Status.IN_PROCESS}>In-process</Option>
              <Option value={Status.PROCESSED}>Processed</Option>
              <Option value={Status.DISCREPANCY_PENDING}>Disc pending</Option>
              <Option value={Status.DISCREPANCY_APPROVAL}>Disc approval</Option>
              <Option value={Status.CONFIRMED}>Confirmed w/o Disc</Option>
              <Option value={Status.APPROVED_WITH_DISCREPANCY}>
                Approved w/ Disc
              </Option>
              <Option value={Status.REJECTED}>Rejected</Option>
              <Option value={Status.TEMPLATE_NOT_SUPPORTED}>
                Template not supported
              </Option>
              <Option value={Status.PO_NOT_FOUND}>PO not found</Option>

              {/* <Option value={Status.PENDING_CONFIRM}>Pending confirm</Option> */}
            </Select>
          </Col>
        </Authorized>

        <Col xs={12} sm={12} md={3} lg={7} xl={7} xl={3}>
          <Button
            type="link"
            className="btb-clear-filter"
            onClick={onClearFilters}
          >
            <span class="btn-icon icon-cross"></span> Clear Filters
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={tableLoading}
        dataSource={invoice && invoice.invoices && invoice.invoices.rows}
        pagination={{
          defaultPageSize: params.limit,
          position: ["none", "bottomCenter"],
          total: invoice.count,
          pageSizeOptions: ["10", "15", "20"],
          locale: { items_per_page: "" },
          showTotal: () =>
            `Showing ${params.skip + 1} - ${params.limit * page} of ${
              invoice.count
            } records.`,
          showSizeChanger: true,
          current: page,
          onChange: (page, pageSize) => onTabChange(page, pageSize),
        }}
      />
    </>
  );
};

export default InvoiceList;
