import React from "react";
import {
  useLocation,
  withRouter,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { AuthorizedRoute } from "../Role-Authorization/Authorized";
import PrivateRoute from "../Routes/private-route";
import Dashboard from "../Components/Dashboard";
import Content from "../Layout/Content";
import Profile from "../Components/User/UserProfile";
import UploadContract from "../Components/Contracts/UploadContract";
import ContractList from "../Components/Contracts/ContractList";
import AddInvoice from "../Components/Invoice/AddInvoice";
import InvoiceDetails from "../Components/Invoice/InvoiceDetails";
import InvoiceList from "../Components/Invoice/InvoiceList";
import UploadInvoice from "../Components/Invoice/UploadInvoice";
import POList from "../Components/PO/POList";
import AddPO from "../Components/PO/AddPO";
import AddPoManaully from "../Components/PO/AddPoManually";
import SelectContractTemplate from "../Components/Contracts/SelectContractTemplate";
import reportTemplates from "../Constants/reportTemplates";

import {
  DASHBOARD_ROUTE,
  UPLOAD_CONTRACT_ROUTE,
  VIEW_CONTRACTS_ROUTE,
  ADD_INVOICE_ROUTE,
  UPLOAD_INVOICE_ROUTE,
  USER_PROFILE_ROUTE,
  VIEW_INVOICES_ROUTE,
  INVOICE_DETAILS_ROUTE,
  VIEW_PO_ROUTE,
  UPLOAD_PO_ROUTE,
  ADD_PO_ROUTE,
  SELECT_CONTRACT_TEMPLATE_ROUTE,
  CONTRACT_TEMPLATE,
  EDIT_TEMPLATED_CONTRACT,
  PO_DISCREPANCY_APPROVER_ROUTE,
  INVOICE_DISCREPANCY_APPROVER_ROUTE,
  ADD_PO_MANUALLY,
  VIEW_TEMPLATED_CONTRACT,
  VIEW_REPORTS_ROUTE,
} from "../Constants/routeNames";
import {
  ROLES_GENERAL,
  ROLES_CONTRACT_VIEW,
  ROLES_INVOICE_VIEW,
  ROLES_PO_VIEW,
  ROLES_PO_ADD_VIEW,
  ROLES_TRUPACTA_TEAM_VIEW,
  ROLES_DISCREPANCY_APPROVER_VIEW,
  ROLES_INVOICE_ADD_VIEW,
  ROLES_CONTRACTS_INVOICE_MANANGER,
  ROLES_INVOICE_MANAGER,
  ROLES_CONTRACT_UPLOAD_MANAGER,
  ROLES_REPORT_VIEW
} from "../utils/constants";
import UploadPO from "../Components/PO/UploadPO";
import SelectedTemplate from "../Components/Contracts/SelectedTemplate";
import EditTemplatedContract from "../Components/Contracts/EditTemplatedContract";
import ApprovePO from "../Components/PO/ApprovePO";
import ApproveInvoice from "../Components/Invoice/ApproveInvoice";
import ReportView from "../Reports";
import ReportDashboard from "../Components/Dashboard/ReportDashboard"

const PRIVATE_ROUTES = [
  {
    component: <Dashboard />,
    path: DASHBOARD_ROUTE,
    exact: true,
    permissions: ROLES_GENERAL,
  },
  {
    component: <Profile />,
    path: USER_PROFILE_ROUTE,
    exact: true,
    permissions: [...ROLES_GENERAL, ...ROLES_REPORT_VIEW],
  },
  {
    component: <UploadContract />,
    path: UPLOAD_CONTRACT_ROUTE,
    exact: true,
    permissions: ROLES_CONTRACT_VIEW,
  },
  {
    component: <ContractList />,
    path: VIEW_CONTRACTS_ROUTE,
    exact: true,
    permissions: ROLES_CONTRACT_VIEW,
  },
  {
    component: <InvoiceList />,
    path: VIEW_INVOICES_ROUTE,
    exact: true,
    permissions: [...ROLES_INVOICE_VIEW, ...ROLES_INVOICE_MANAGER],
  },
  {
    component: <AddInvoice />,
    path: `${ADD_INVOICE_ROUTE}:id`,
    exact: true,
    permissions: [...ROLES_INVOICE_ADD_VIEW, ...ROLES_INVOICE_MANAGER],
  },
  {
    component: <UploadInvoice />,
    path: UPLOAD_INVOICE_ROUTE,
    exact: true,
    permissions: [...ROLES_INVOICE_VIEW, ...ROLES_INVOICE_MANAGER],
  },
  {
    component: <POList />,
    path: VIEW_PO_ROUTE,
    exact: true,
    permissions: ROLES_PO_VIEW,
  },
  {
    component: <UploadPO />,
    path: UPLOAD_PO_ROUTE,
    exact: true,
    permissions: ROLES_PO_ADD_VIEW,
  },
  {
    component: <AddPO />,
    path: `${ADD_PO_ROUTE}:id`,
    exact: true,
    permissions: ROLES_PO_VIEW,
  },
  {
    component: <AddPoManaully />,
    path: `${ADD_PO_MANUALLY}`,
    exact: true,
    permissions: ROLES_PO_VIEW,
  },
  {
    component: <SelectContractTemplate />,
    path: SELECT_CONTRACT_TEMPLATE_ROUTE,
    exact: true,
    permissions: ROLES_TRUPACTA_TEAM_VIEW,
  },
  {
    component: <SelectedTemplate />,
    path: `${CONTRACT_TEMPLATE}`,
    exact: true,
    permissions: ROLES_TRUPACTA_TEAM_VIEW,
  },
  {
    component: <EditTemplatedContract />,
    path: `${EDIT_TEMPLATED_CONTRACT}`,
    exact: true,
    permissions: ROLES_TRUPACTA_TEAM_VIEW,
  },
  {
    component: <EditTemplatedContract />,
    path: `${VIEW_TEMPLATED_CONTRACT}`,
    exact: true,
    permissions: [...ROLES_CONTRACTS_INVOICE_MANANGER, ...ROLES_CONTRACT_UPLOAD_MANAGER],
  },
  {
    component: <InvoiceDetails />,
    path: INVOICE_DETAILS_ROUTE,
    exact: true,
    permissions: [...ROLES_INVOICE_VIEW, ...ROLES_INVOICE_MANAGER],
  },
  {
    component: <ApprovePO />,
    path: `${PO_DISCREPANCY_APPROVER_ROUTE}:id`,
    exact: true,
    permissions: ROLES_DISCREPANCY_APPROVER_VIEW,
  },
  {
    component: <ApproveInvoice />,
    path: `${INVOICE_DISCREPANCY_APPROVER_ROUTE}:id`,
    exact: true,
    permissions: ROLES_DISCREPANCY_APPROVER_VIEW,
  },
  {
    component: <ReportDashboard />,
    path: VIEW_REPORTS_ROUTE,
    exact: true,
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_GENERAL],
  },
];

const REPORT_TEMPLATE_PRIVATE_ROUTES = reportTemplates.map((report) => {
  return ({
  component: <ReportView label={report.label} filters={report.filters} columns={report.columns} title={report.title} report={report}/>,
  path: `${VIEW_REPORTS_ROUTE}/${report.id}`,
  exact: true,
  permissions: report.permissions,
})});

const DefaultContainer = () => {
  return (
    <Switch>
      {/* Private Routes */}
      {[...PRIVATE_ROUTES, ...REPORT_TEMPLATE_PRIVATE_ROUTES].map(
        (d, index) => (
          <PrivateRoute path={d.path} key={index} exact={d.exact}>
            <AuthorizedRoute roleList={d.permissions}>
              {d.path === DASHBOARD_ROUTE || d.path === VIEW_REPORTS_ROUTE ? (
                d.component
              ) : (
                <Content>{d.component}</Content>
              )}
            </AuthorizedRoute>
          </PrivateRoute>
        )
      )}
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default withRouter(DefaultContainer);
