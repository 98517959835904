export const GET_CONTRACTS_COUNT = "GET_CONTRACTS_COUNT";
export const GET_CONTRACT_AND_INVOICE_COUNT = "GET_CONTRACT_AND_INVOICE_COUNT";
export const GET_RECENT_CONTRACTS_AND_INVOICES = "GET_RECENT_CONTRACTS_AND_INVOICES";
export const GET_RECENT_CONTRACTS = "GET_RECENT_CONTRACTS";
export const GET_CONTRACT_RENEWAL = 'GET_CONTRACT_RENEWAL';
export const GET_DISCREPENCY = 'GET_DISCREPENCY';
export const GET_PROCESSING_TREND = 'GET_PROCESSING_TREND';
export const GET_TOP_5_SKU = 'GET_TOP_5_SKU';
export const GET_TOP_5_VENDOR = 'GET_TOP_5_VENDOR';
export const GET_VENDOR_SCORE = 'GET_VENDOR_SCORE';
export const GET_TOTAL_COUNT = 'GET_TOTAL_COUNT';
export const GET_WEEKLY_CONTRACTS = "GET_WEEKLY_CONTRACTS";
export const GET_WEEKLY_CONTRACTS_AND_INVOICES = "GET_WEEKLY_CONTRACTS_AND_INVOICES";
export const GET_RECENT_MONTHLY_CONTRACTS = "GET_RECENT_MONTHLY_CONTRACTS";
export const GET_PENDING_CONTRACTS = "GET_PENDING_CONTRACTS";
export const GET_PROCESSED_CONTRACTS = "GET_PROCESSED_CONTRACTS";
export const GET_PO_COUNTS = "GET_PO_COUNTS";
export const GET_WEEKLY_PO = "GET_WEEKLY_PO";
export const GET_DISC_APPROVAL_PO = "GET_DISC_APPROVAL_PO";
export const GET_REJECTED_PO = "GET_REJECTED_PO";
export const GET_RECENT_PO = "GET_RECENT_PO";
export const GET_PO_INVOICE_APPROVAL_COUNTS = "GET_PO_INVOICE_APPROVAL_COUNTS";
export const GET_WEEKLY_PO_INVOICES = "GET_WEEKLY_PO_INVOICES";
export const GET_RECENT_ENTITY_RECORDS = "GET_RECENT_ENTITY_RECORDS";
export const GET_WEEKLY_PO_CONTRACT_INVOICES = "GET_WEEKLY_PO_CONTRACT_INVOICES";
export const GET_INVOICE_COUNTS = "GET_INVOICE_COUNTS";
export const GET_WEEKLY_INVOICE = "GET_WEEKLY_INVOICE";
export const GET_DISC_APPROVAL_INVOICE = "GET_DISC_APPROVAL_INVOICE";
export const GET_REJECTED_INVOICE = "GET_REJECTED_INVOICE";
export const GET_RECENT_INVOICE = "GET_RECENT_INVOICE";
export const GET_CURRENT_MONTH_PO_CONTRACT_INVOICES = "GET_CURRENT_MONTH_PO_CONTRACT_INVOICES";

export const GET_COMMIT_VERSION = "GET_COMMIT_VERSION";
