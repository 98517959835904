import {
  getAllTemplates,
  getTemplateByContractId,
  getTemplateById,
} from "../../Services/Templates/templates";
import {
  GET_ALL_TEMPLATES,
  SHOW_LOADER,
  HIDE_LOADER,
  GET_TEMPLATE,
  GET_TEMPLATE_BY_CONTRACT_ID,
  CLEAR_TEMPLATED_CONTRACT,
} from "../Types";
import { actionDispatch } from "./actionDispatcher";

export const fetchAllTemplates = () => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await getAllTemplates();
    dispatch(actionDispatch(GET_ALL_TEMPLATES, response.data));
    dispatch(actionDispatch(HIDE_LOADER, { loader: false }));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER, { loader: false }));
  }
};

export const fetchTemplatesById = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const response = await getTemplateById(id);
    dispatch(actionDispatch(GET_TEMPLATE, response.data));
    dispatch(actionDispatch(HIDE_LOADER, { loader: false }));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER, { loader: false }));
  }
};

export const fetchTemplatesByContractId = (id) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  dispatch(clearTemplate());
  try {

    const response = await getTemplateByContractId(id);
    dispatch(actionDispatch(GET_TEMPLATE_BY_CONTRACT_ID, response.data));
    dispatch(actionDispatch(HIDE_LOADER, { loader: false }));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER, { loader: false }));
  }
};

const clearTemplate = () => (dispatch) => {
  dispatch(actionDispatch(CLEAR_TEMPLATED_CONTRACT, {}));
}