import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";
import { organizations } from "../../reducers/organizations";

export const getPoInvoiceCountWithStatus = (
  filters,
  entity,
  isAuthorized,
  orgId
) => {
  entity = entity ? entity : "all";
  return HttpRequest(
    "get",
    `${endPoints.GET_PO_INVOICE_WITH_STATUS}/${entity}/template-not-supported`,
    null,
    null,
    { ...filters, organization: !isAuthorized ? orgId : filters.organization }
  );
};

export const getProcessedPOInvoice = (filters, entity, isAuthorized, orgId) => {
  entity = entity ? entity : "all";
  return HttpRequest(
    "get",
    `${endPoints.GET_PROCESSED_PO_AND_INVOICES}/${entity}`,
    null,
    null,
    { ...filters, organization: !isAuthorized ? orgId : filters.organization }
  );
};

export const getContracts = (filters, isAuthorized, orgId) =>
  HttpRequest("get", endPoints.GET_ALL_CONTRACTS, null, null, {...filters, organization: !isAuthorized ? orgId : filters.organization});

export const getProcessedContractsWithTime = (filters, isAuthorized, orgId) =>
  HttpRequest(
    "get",
    endPoints.GET_PROCESSED_CONTRACTS_WITH_TIME,
    null,
    null,
    { ...filters, organization: !isAuthorized ? orgId : filters.organization }
  );

export const getLoginFrequency = (filters, isAuthorized, orgId) =>
  HttpRequest("get", endPoints.GET_LOGIN_FREQUENCY, null, null, { ...filters, organization: !isAuthorized ? orgId : filters.organization });

export const getProcessedAndRejectedPOInvoice = (
  filters,
  entity,
  isAuthorized,
  orgId
) => {
  entity = entity ? entity : "all";
  return HttpRequest(
    "get",
    `${endPoints.GET_APPROVED_REJECTED_PO_INVOICE}/${entity}`,
    null,
    null,
    { ...filters, organization: !isAuthorized ? orgId : filters.organization }
  );
};

export const getAggregateDiscrepancies = (
  filters,
  entity,
  isAuthorized,
  orgId
) =>
  HttpRequest(
    "get",
    `${endPoints.GET_AGGREGATE_DISCREPANCIES}/${entity}`,
    null,
    null,
    { ...filters, organization: !isAuthorized ? orgId : filters.organization }
  );
