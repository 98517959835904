import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Form,
  Typography,
  Avatar,
  DatePicker,
  Spin,
  Tooltip,
  Switch,
} from "antd";
import { FilePdfOutlined, DeleteOutlined } from "@ant-design/icons";
import history from "../../History/history";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { UPLOAD_INVOICE_ROUTE } from "../../Constants/routeNames";
import {
  actionDispatch,
  getInvoiceById,
  updateInvoiceAndItemsById,
  getVendors,
  getPoFile,
  clearPoFileUrl,
  triggerNotification,
  getContractFile,
  clearContractFile,
  getInvoiceFile,
  clearInvoiceFile,
} from "../../actions/Creators";
import "./Invoice.scss";
import Authorized from "../../Role-Authorization/Authorized";
import Notification from "../../Common/Notification";
import VendorDropdown from "../../Common/VendorDropdown";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import {
  ROLES_INVOICE_ADD_VIEW,
  ROLES_INVOICE_MANAGER,
} from "../../utils/constants";
import { Status } from "./InvoiceStatus";
import { isEmpty } from "../../utils/isEmpty";
import { openPDF } from "../../utils/s3Upload";

const { Text } = Typography;
const discrepancyText = "In respective purchase order";

const AddInvoice = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [tableData, setTableData] = useState([{}]);
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const [isDisabled, setIsDisable] = useState(false);
  const [vendorInfo, setVendorInfo] = useState({});
  const [invoiceWithoutPo, setInvoiceWithoutPo] = useState(false);
  const vendor = useSelector((state) => state.vendor);
  const notification = useSelector((state) => state.commonReducer.notification);
  const fileLoader = useSelector((state) => state.commonReducer.fileLoader);
  const invoice = useSelector((state) => state.invoice.invoice);
  const user = useSelector((state) => state.auth.user);
  const loader = useSelector((state) => state.commonReducer.loader);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );
  const fileUrl = useSelector((state) => state.po.poFileUrl);
  const contractFileUrl = useSelector(
    (state) => state.contract.associatedContractFile
  );
  const invoiceFileUrl = useSelector((state) => state.invoice.invoiceFileUrl);

  useEffect(() => {
    dispatch(getVendors(organization.id));
    dispatch(getInvoiceById(id));
  }, []);

  useEffect(() => {
    tableForm.resetFields();
    invoice &&
      invoice.vendor &&
      setVendorInfo({ key: invoice.vendor.id, value: invoice.vendor.title });
    if (
      invoice &&
      (invoice.status === Status.DISCREPANCY_PENDING ||
        invoice.status === Status.REJECTED ||
        invoice.status === Status.PROCESSED ||
        invoice.status === Status.TEMPLATE_NOT_SUPPORTED ||
        invoice.status === Status.PO_NOT_FOUND)
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    let newobj = {};
    invoice.invoice_items &&
      invoice.invoice_items.forEach((obj, i) => {
        Object.keys(obj).forEach((key) => {
          newobj[`${key}-${i}`] = obj[key];
        });
      });
    tableForm.setFieldsValue({
      ...newobj,
    });

    form.setFieldsValue({
      address: invoice.address,
      amount: invoice.amount,
      associated_contract: invoice.associated_contract,
      associated_po: invoice.associated_po,
      contact_number: invoice.contact_number,
      contact_person: invoice.contact_person,
      discrepancies: invoice.discrepancies,
      billing_email: invoice.billing_email,
      invoice_items: invoice.invoice_items,
      invoice_number: invoice.invoice_number,
      invoice_wo_po: invoice.invoice_wo_po,
      issue_date: invoice.issue_date ? moment(invoice.issue_date) : null,
    });
    setValueToTotalAmount();
    invoice && setInvoiceWithoutPo(invoice.invoice_wo_po);
  }, [invoice]);

  const setValueToTotalAmount = () => {
    const updatedValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(updatedValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    let dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value) => {
        result[value] = updatedValues[`${value}-${index}`];
        return result;
      }, {});
    });
    const temp = [];
    let newobj = {};

    dto.forEach((i) => {
      i.total = convertToCurrencyNumber(i.total);
      if (i.total && !isNaN(i.total)) temp.push(Number(i.total));
      else temp.push(0);
    });

    let discountTotalPrice = 0;
    temp.forEach((amount) => {
      if (!isNaN(amount) || amount !== "") {
        discountTotalPrice += amount;
      }
    });
    setTotalAmount(convertToCurrencyNumber(discountTotalPrice));
  };

  let discrepancyCount = 0;
  invoice &&
    !isEmpty(invoice.discrepancy) &&
    invoice.discrepancies &&
    invoice.discrepancies.data &&
    invoice.discrepancies.data.forEach((disc) => {
      if (disc.fields) {
        discrepancyCount += disc.fields.length;
      }
    });

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      render: (text, object, index) => index + 1,
    },
    {
      title: "SKU Number",
      dataIndex: "sku_number",
      key: "sku_number",
      ellipsis: true,
      render: (quantity, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );

        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "sku_number")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`sku_number-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide SKU Number.",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "sku_number") {
                    return (
                      <Tooltip
                        overlayClassName="table-tooltip"
                        title={`Invalid SKU Number`}
                        color="#f87b57"
                        placement="bottom"
                      >
                        <span className="icon-warning"></span>
                      </Tooltip>
                    );
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "SKU Description",
      dataIndex: "item_name",
      key: "item_name",
      ellipsis: true,
      render: (item_name, _, index) => (
        <Form.Item name={`item_name-${index}`}>
          <Input disabled={isDisabled} />
        </Form.Item>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );

        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "quantity")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`quantity-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Quantity.",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  disabled={isDisabled}
                  allowNegative={false}
                  decimalScale={0}
                />
              </Form.Item>

              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "quantity") {
                    if (invoice.invoice_wo_po) {
                      return (
                        <Tooltip
                          overlayClassName="table-tooltip"
                          title={`Contractual Quantity Range is [${
                            data.contract_skuMin
                          }-${
                            data.contract_skuMax
                              ? data.contract_skuMax
                              : "infinite"
                          }]`}
                          color="#f87b57"
                          placement="bottom"
                        >
                          <span className="icon-warning"></span>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          overlayClassName="table-tooltip"
                          title={`${discrepancyText} Quantity is ${data.po_item_value}`}
                          color="#f87b57"
                          placement="bottom"
                        >
                          <span className="icon-warning"></span>
                        </Tooltip>
                      );
                    }
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "Net Price",
      dataIndex: "final_price",
      key: "final_price",
      ellipsis: true,
      render: (final_price, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );

        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "final_price")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`final_price-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Net Price.",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isDisabled}
                  allowNegative={false}
                />
              </Form.Item>

              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "final_price") {
                    if (invoice.invoice_wo_po) {
                      return (
                        <Tooltip
                          overlayClassName="table-tooltip"
                          title={`Contractual Net Price is $${data.contract_value
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          color="#f87b57"
                          placement="bottom"
                        >
                          <span className="icon-warning"></span>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          overlayClassName="table-tooltip"
                          title={`${discrepancyText} Net Price is $${data.po_item_value
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          color="#f87b57"
                          placement="bottom"
                        >
                          <span className="icon-warning"></span>
                        </Tooltip>
                      );
                    }
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "Net Total",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      render: (total, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );

        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "total")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`total-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Net Price.",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isDisabled}
                  allowNegative={false}
                  onChange={setValueToTotalAmount}
                />
              </Form.Item>

              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "total") {
                    if (invoice.invoice_wo_po) {
                      return (
                        <Tooltip
                          overlayClassName="table-tooltip"
                          title="Invalid Net Total Value"
                          color="#f87b57"
                          placement="bottom"
                        >
                          <span className="icon-warning"></span>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          overlayClassName="table-tooltip"
                          title={`${discrepancyText} Total is $${data.po_item_value
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          color="#f87b57"
                          placement="bottom"
                        >
                          <span className="icon-warning"></span>
                        </Tooltip>
                      );
                    }
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "actions",
      align: "center",
      width: invoice.status === Status.TEMPLATE_NOT_SUPPORTED ? "50px" : "0px",
      render: (_, row, index) => {
        return (
          <>
            {invoice.status === Status.TEMPLATE_NOT_SUPPORTED && (
              <Tooltip title="Delete">
                <DeleteOutlined
                  style={{ cursor: "pointer" }}
                  className="clr-link"
                  onClick={() => deleteRow(row, index)}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const onUpdateHandler = async (status, shouldRecheck, comment) => {
    await form.validateFields();
    await tableForm.validateFields();
    const tableValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(tableValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    let dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value, colIndex) => {
        result[value] = tableValues[`${value}-${index}`];
        return result;
      }, {});
    });
    dto = dto.map((record, index) => {
      record.quantity =
        record.quantity && convertToCurrencyNumber(record.quantity);
      record.final_price =
        record.final_price && convertToCurrencyNumber(record.final_price);
      record.total = record.total && convertToCurrencyNumber(record.total);
      return { ...invoice.invoice_items[index], ...record };
    });
    let invoiceDetails = {
      ...form.getFieldsValue(),
      issue_date: form.getFieldsValue().issue_date.format("YYYY-MM-DD"),
      amount: totalAmount && totalAmount,
      vendor_id: vendorInfo.key,
      status: status,
    };
    let invoiceItemsDetails = [...dto];
    let historyObj = {
      userId: user.id,
      comment: comment,
      status: status,
    };
    dispatch(
      updateInvoiceAndItemsById(
        id,
        { invoiceDetails, invoiceItemsDetails },
        historyObj,
        shouldRecheck
      )
    );
  };

  const convertToCurrencyNumber = (numString) => {
    let currency = numString && numString.toString().replace(/[$,%\s]/g, "");
    if (currency === "") {
      return null;
    }
    return Number(currency);
  };
  const onFileSelect = (file) => {
    if (file.type == "invoice") {
      dispatch(getInvoiceFile(file.key));
    }
    if (file.type == "contract") {
      dispatch(getContractFile(file.key));
    } else if (file.type == "po") {
      dispatch(getPoFile(file.key));
    }
  };
  useEffect(async () => {
    if (fileUrl) {
      await openPDF(fileUrl);
      dispatch(clearPoFileUrl());
    }
  }, [fileUrl]);

  useEffect(async () => {
    if (contractFileUrl) {
      await openPDF(contractFileUrl);
      dispatch(clearContractFile());
    }
  }, [contractFileUrl]);

  useEffect(async () => {
    if (invoiceFileUrl) {
      await openPDF(invoiceFileUrl);
      dispatch(clearInvoiceFile());
    }
  }, [invoiceFileUrl]);

  const addRow = () => {
    let data = tableData;
    data.push({
      item_name: "",
      vat: "",
      final_price: "",
      total: "",
      invoice_id: "",
    });
    setTableData([...data]);
  };
  const deleteRow = (row, index) => {
    let data = tableData;
    if (tableData.length < 2) {
      dispatch(triggerNotification("error", "At least 1 SKU must be included"));
      return;
    } else {
      let fieldValues = tableForm.getFieldsValue();
      data = data.filter((d, i) => {
        return i !== index;
      });
      tableForm.resetFields();
      setTableData([...data]);
      for (let prop in fieldValues) {
        delete fieldValues[`${prop.split("-")[0]}-${index}`];
      }
      let obj = {};
      let maxRows;
      let columnsList = Object.keys(fieldValues).map((dt) => {
        maxRows = dt.split("-")[1];
        return `${dt.split("-")[0]}`;
      });
      columnsList = [...new Set(columnsList)];
      const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index1) => {
        return columnsList.reduce((result, value, colIndex) => {
          if (index1 !== index) {
            result[value] = fieldValues[`${value}-${index1}`];
            return result;
          }
        }, {});
      });
      let filtered = dto.filter((el) => {
        return el != null;
      });

      filtered.map((d, i) => {
        Object.keys(d).map((dt) => {
          obj[`${dt}-${i}`] = d[dt];
        });
      });
      tableForm.setFieldsValue({
        ...obj,
      });
    }
  };

  return (
    <Spin size="large" spinning={loader}>
      <Row className="content-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>View Invoice</h2>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="d-flex f-justify-end"
        >
          <Link to={UPLOAD_INVOICE_ROUTE}>
            <Button className="btn-primary">
              {" "}
              <span className="btn-icon icon-upload"></span>Upload New
            </Button>
          </Link>
        </Col>
      </Row>

      <Form
        id="form"
        form={form}
        className="form-vertical-t2"
        layout="vertical"
        validateTrigger="onBlur"
      >
        <Row className="">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="form-vertical-t2 form-horizontal-t2 form-layout-3">
              {" "}
              <Row className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="vendor" label="Vendor">
                    <VendorDropdown
                      disabled={isDisabled}
                      vendor={vendor}
                      id={invoice.vendor_id}
                      getValue={(val) => setVendorInfo(val)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Row className="">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="select-vendor-details-wrapper"
              >
                <div className="address-details">
                  <p className="label-vendor-name">{vendorInfo.value}</p>
                  <p className="label-vendor-number">+1 (575) 099 5820</p>
                  <p>957 Linda Drive New Kensington, PA United States</p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="d-flex f-justify-end"
          >
            {" "}
            <div className="w-100 form-horizontal-t2 form-layout-2">
              <Form.Item
                label="Invoice Issue Date"
                name="issue_date"
                rules={[
                  {
                    required: true,
                    message: "Please provide the issue date",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  disabled={isDisabled}
                  placeholder="YYYY-MM-DD"
                />
              </Form.Item>
              <Form.Item label="Invoice #" name="invoice_number">
                <Input disabled={isDisabled} />
              </Form.Item>
              <Form.Item label="Invoice w/o po" name="invoice_wo_po">
                <Switch
                  disabled={isDisabled}
                  value={invoiceWithoutPo}
                  checked={invoiceWithoutPo}
                  onChange={(e) => setInvoiceWithoutPo(e)}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <h4 className="mt-1">Billing Details</h4>
        <Row className="" gutter={24} align="top">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contact Person" name="contact_person">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contact No." name="contact_number">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Address" name="address">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>

        <Row className="" gutter={24} align="top">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              label="Email"
              name="billing_email"
              rules={[
                {
                  type: "email",
                  message: "Email is not valid.",
                },
              ]}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>

        <h4 className="mt-1">Other Details</h4>
        <Row className="" gutter={24} align="top" layout="vertical">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contract #" name="associated_contract">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Purchase Order #" name="associated_po">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <h4 className="mt-1">SKU Details</h4>

      <Form form={tableForm}>
        <Table
          loading={loader && !invoice && !invoice.invoice_items}
          form={tableForm}
          className={`table-t2 table-form-inline  ${
            invoice.status === Status.TEMPLATE_NOT_SUPPORTED
              ? ""
              : "table-add-po-sp"
          }`}
          size="middle"
          columns={columns}
          dataSource={
            invoice && invoice.status === Status.TEMPLATE_NOT_SUPPORTED
              ? tableData
              : invoice.invoice_items
          }
          pagination={false}
          summary={() => {
            return (
              <>
                {/* <Table.Summary.Row className="invoice-total-wrapper">
                  <Table.Summary.Cell
                    colSpan={6}
                    className="itw-row-1 align-right pr-2"
                  >
                    VAT (13%)
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1} className="itw-row-1">
                    <Text type="" className="bold">
                      35%
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row> */}

                <Table.Summary.Row className="invoice-total-wrapper">
                  {invoice.status === Status.TEMPLATE_NOT_SUPPORTED && (
                    <Table.Summary.Cell
                      colSpan={4}
                      className="itw-row-2 align-right label-total-amount pr-2"
                    >
                      <Button
                        className="btn-tertiary btn-clr-type3 float-left"
                        onClick={addRow}
                      >
                        <span className="btn-icon icon-add-new"></span> Add Row
                      </Button>
                    </Table.Summary.Cell>
                  )}

                  {invoice.status !== Status.TEMPLATE_NOT_SUPPORTED && (
                    <Table.Summary.Cell
                      colSpan={4}
                      className="itw-row-2 align-right label-total-amount pr-2"
                    >
                      {discrepancyCount > 0 && (
                        <div className="label-descrepancy-in-data">
                          {discrepancyCount}{" "}
                          {discrepancyCount < 2
                            ? "discrepancy"
                            : "discrepancies"}{" "}
                          found
                        </div>
                      )}
                    </Table.Summary.Cell>
                  )}

                  <Table.Summary.Cell className="itw-row-2 align-right label-total-amount pr-2">
                    Total Amount
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    colSpan={2}
                    className="itw-row-2 label-amount"
                  >
                    <Text type="">
                      {`$${totalAmount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Form>
      <Spin spinning={fileLoader}>
        {!isEmpty(invoice.document && invoice.document.key) && (
          <Row>
            <h4 className="mt-1">Uploaded Invoice</h4>
            <Col span={24} key={invoice.document.key}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onFileSelect(invoice.document);
                }}
              >
                <FilePdfOutlined /> {invoice.document.name}
              </a>
            </Col>
          </Row>
        )}

        {!isEmpty(invoice.associatedDocs) && (
          <Row>
            <h4 className="mt-1">Associated Documents</h4>
            {invoice.associatedDocs.map((d, i) => {
              return (
                <Col span={24} key={d.key}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onFileSelect(d);
                    }}
                  >
                    <FilePdfOutlined /> {d.name}
                  </a>
                </Col>
              );
            })}
          </Row>
        )}
      </Spin>
      <Row className="mt-2" align="top">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button
            onClick={() => {
              history.goBack();
            }}
            className="btn-primary btn-t2 mr-1"
          >
            <span class="btn-icon icon-arrow-square-left"></span> Back
          </Button>
          <Authorized
            roleList={[...ROLES_INVOICE_ADD_VIEW, ...ROLES_INVOICE_MANAGER]}
          >
            {invoice &&
              (invoice.status === Status.DISCREPANCY_PENDING ||
                invoice.status === Status.REJECTED ||
                invoice.status === Status.PROCESSED ||
                invoice.status === Status.TEMPLATE_NOT_SUPPORTED ||
                invoice.status === Status.PO_NOT_FOUND) && (
                <Button
                  className="btn-primary mr-1"
                  onClick={() =>
                    onUpdateHandler(
                      Status.IN_PROCESS,
                      true,
                      "Invoice sent for discrepancy check"
                    )
                  }
                >
                  <span className="btn-icon icon-tick"></span> Confirm
                </Button>
              )}

            {invoice &&
              invoice.discrepancy &&
              (invoice.status === Status.DISCREPANCY_PENDING ||
                invoice.status === Status.REJECTED) && (
                <Button
                  className="btn-primary"
                  onClick={() =>
                    onUpdateHandler(
                      Status.DISCREPANCY_APPROVAL,
                      false,
                      "Invoice sent for Approval"
                    )
                  }
                >
                  <span className="btn-icon icon-tick"></span> Send for Approval
                </Button>
              )}
          </Authorized>
        </Col>
      </Row>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
    </Spin>
  );
};

export default AddInvoice;
