import {
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  GET_REPORT,
  SET_FILTERS,
  SET_CURRENT_REPORT_INDEX
} from "../Types";
import { actionDispatch } from ".";
import { UNPERFORMED_GET_ACTION } from "../../Constants/validation";
import {
  getPoInvoiceCountWithStatus,
  getProcessedPOInvoice,
  getProcessedAndRejectedPOInvoice,
  getContracts,
  getProcessedContractsWithTime,
  getLoginFrequency,
  getProcessedAndRejectedPO,
  getProcessedAndRejectedInvoice,
  getAggregateDiscrepancies
} from "../../Services/Reports/reports";

// Get PO and Invoice count with status 
export const getPoInvoiceCountAndStatus = (filters, entity, isAuthorized = false, orgId = null) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getPoInvoiceCountWithStatus(filters, entity, isAuthorized, orgId);
    dispatch(actionDispatch(GET_REPORT, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

// Get Processed PO and Invoice 
export const getProcessedPOAndInvoice = (filters, entity, isAuthorized  =false, orgId = null) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getProcessedPOInvoice(filters, entity, isAuthorized, orgId);
    dispatch(actionDispatch(GET_REPORT, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};


export const getAllContracts = (filters, isAuthorized = false, orgId = null) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await getContracts(filters,isAuthorized, orgId);
    dispatch(actionDispatch(GET_REPORT, res.data));
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response && ex.response.data
            ? ex.response.data.message
            : UNPERFORMED_GET_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};

export const getProcessedContractsWithTimeDiff =
  (filters, isAuthorized = false, orgId = null) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      const res = await getProcessedContractsWithTime(filters, isAuthorized, orgId);
      dispatch(actionDispatch(GET_REPORT, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

  export const getLoginFrequencyData =
  (filters, isAuthorized = false, orgId = null) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      const res = await getLoginFrequency(filters, isAuthorized, orgId);
      dispatch(actionDispatch(GET_REPORT, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

  export const getProcessedAndRejectedPOInvoices =
  (filters, entity, isAuthorized = false, orgId = null) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      const res = await getProcessedAndRejectedPOInvoice(filters, entity,isAuthorized, orgId);
      dispatch(actionDispatch(GET_REPORT, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

  
  export const getAggregateDiscrepanciesOfPoInvoice =
  (filters, entity, isAuthorized = false, orgId = null) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      const res = await getAggregateDiscrepancies(filters, entity, isAuthorized, orgId);
      dispatch(actionDispatch(GET_REPORT, res.data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

  export const setFilterObject =
  (data) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      dispatch(actionDispatch(SET_FILTERS, data));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message:
            ex.response && ex.response.data
              ? ex.response.data.message
              : UNPERFORMED_GET_ACTION,
          open: true,
          type: "error",
        })
      );
    }
  };

  export const setCurrentReportIndex =
  (index) => async (dispatch) => {
    dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
    try {
      dispatch(actionDispatch(SET_CURRENT_REPORT_INDEX, index));
      dispatch(actionDispatch(HIDE_LOADER));
    } catch (ex) {
      dispatch(actionDispatch(HIDE_LOADER));
    }
  };


