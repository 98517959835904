export const VIEW_CONTRACTS = "VIEW_CONTRACTS";
export const UPLOAD_CONTRACT = "UPLOAD_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const GET_CONTRACTS_BY_ID = "GET_CONTRACTS_BY_ID";
export const CREATE_TEMPLATED_CONTRACT = "CREATE_TEMPLATED_CONTRACT";
export const UPDATE_CONTRACT = "UPDATE_CONTRACT";
export const UPDATE_TEMPLATED_CONTRACT = "UPDATE_TEMPLATED_CONTRACT";
export const CLEAR_TEMPLATED_CONTRACT = "CLEAR_TEMPLATED_CONTRACT";
export const GET_SELECTED_CONTRACT_TEMPLATE_FILE = "GET_SELECTED_CONTRACT_TEMPLATE_FILE";
export const CLEAR_SELECTED_CONTRACT_TEMPLATE_FILE = "CLEAR_SELECTED_CONTRACT_TEMPLATE_FILE";
export const CLEAR_FILE_URL = "CLEAR_FILE_URL";
export const GET_CONTRACT_FILE = "GET_CONTRACT_FILE";
export const CLEAR_CONTRACT_FILE = "CLEAR_CONTRACT_FILE";