import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const getUserInfo = () => {
  return HttpRequest("get", endPoints.GET_USER);
}

export const updateUserInfo = (data) => {
  return HttpRequest("post", endPoints.UPDATE_USER, data);
}

export const getUsers = () => {
  return HttpRequest("get", endPoints.GET_ALL_USERS);
}

export const getUsersOfOrg = (org_id) => {
  return HttpRequest("get", `${endPoints.GET_USERS_WRT_ORG}/${org_id}`);
}

