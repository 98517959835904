import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const getInvoiceList = (org_id, params) => {
  return HttpRequest(
    "get",
    `${endPoints.GET_ALL_INVOICES}${org_id}`,
    null,
    null,
    params
  );
};

export const getInvoice = (id) => {
  return HttpRequest("get", `${endPoints.GET_INVOICE_BY_ID}${id}`);
};

export const createNewInvoices = (data) => {
  return HttpRequest("post", endPoints.CREATE_INVOICES, data);
};

export const updateInvoice = (id, data) => {
  return HttpRequest("put", `${endPoints.UPDATE_INVOICE_BY_ID}${id}`, data);
};

export const updateInvoiceAndInvoiceItems = (id, data) => {
  return HttpRequest("put", `${endPoints.UPDATE_INVOICE_AND_INVOICE_ITEMS_BY_ID}${id}`, data);
}

export const removeInvoice = (id) => {
  return HttpRequest("delete", `${endPoints.DELET_INVOICE}${id}`);
};

export const getInvoiceListWithDisc = (org_id, params) => {
  return HttpRequest(
    "get",
    `${endPoints.GET_INVOICES_WITH_DISC}${org_id}`,
    null,
    null,
    {...params, type: "invoices"}
  );
};

export const recalculateInvoiceDiscrepancy = (id, data) => {
  return HttpRequest("post", `${endPoints.RECHECK_FOR_INVOICE_DISCREPANCY}${id}`, data);
};

export const updateInvoiceStatusWithComment = (id, data) => {
  return HttpRequest("put", `${endPoints.UPDATE_INVOICE_COMMENT_STATUS}${id}`, data);
}

export const getInvoiceListForInvoiceManager = (org_id, params) => {
  return HttpRequest(
    "get",
    `${endPoints.GET_INVOICE_LIST_FOR_MANAGER}${org_id}`,
    null,
    null,
    params
  );
};