export const FIELD_TYPES = {
  INPUT_STRING: "string",
  INPUT_NUMBER: "number",
  DROPDOWN: "dropdown",
  TOGGLE: "boolean",
  DATEPICKER: "date",
  RADIO_BUTTON: "radio",
  CHECKBOX: "checkbox",
  VENDOR_SELECTION: "vendor-dropdown-string",
  ATTACHMENT: "attachment",
  TEXTAREA: "textarea",
  TABLE: "table"
};
