import axios from "axios";
import history from "../History/history";
import { clearLocalStorage, getFromLocal } from "./cache";
import { LOCAL_STORAGE_KEYS } from "./constants";
import { ConfigureStore } from "../store";
import { LOGOUT } from "../actions/Types";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : `http://demo4.folio3.com:3002/api`,
});
console.log("baseurl", process.env.REACT_APP_BASE_URL);

axiosInstance.interceptors.request.use((config) => {
  // add authorization token
  const token = getFromLocal(LOCAL_STORAGE_KEYS.TOKEN, false, true);
  config.headers.token = `${token}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response && error.response.status === 401) {
      clearLocalStorage();
      ConfigureStore.dispatch({ type: LOGOUT });
      history.push("/login");
    }
    return Promise.reject(error);
  }
);
