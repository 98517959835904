import HttpRequest from "../../utils/httpRequests";
import endPoints from "../../utils/endPoints";

export const createEntityComment = (data) => {
  return HttpRequest("post", endPoints.CREATE_COMMENT, {...data});
};

export const getEntityComments = (id, params) => {
  return HttpRequest("get", `${endPoints.GET_ENTITY_COMMENTS}/${id}`,null, null, params);
};

export const getCommentsReasons = (id, query) => {
  return HttpRequest("get", `${endPoints.GET_COMMENTS_REASONS}/${id}`, null, null, query);
};
