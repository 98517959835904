import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Space,
  Button,
  Typography,
  Avatar,
  Form,
  Input,
  Spin,
  Badge,
} from "antd";
import {
  getInvoiceById,
  getVendors,
  createComment,
  updateInvoiceById,
  getPoFile,
  clearPoFileUrl,
  getContractFile,
  clearContractFile,
  getInvoiceFile,
  clearInvoiceFile,
} from "../../actions/Creators";
import { useDispatch, useSelector } from "react-redux";
import { FilePdfOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Loader from "../../Common/Loader";
import dummyVendorLogo from "../../assets/images/abc-aus.jpg";
import { isEmpty } from "../../utils/isEmpty";
import { Status, badgeColor } from "./InvoiceStatus";
import history from "../../History/history";
import Modal from "../../Common/Modal";
import { openPDF } from "../../utils/s3Upload";
import { dateToLocal } from "../../utils/moment";

const { Text } = Typography;

const ApprovePO = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [rejectForm] = Form.useForm();
  const [approveForm] = Form.useForm();
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [vendorId, setVendorId] = useState();
  const [vendorDetails, setVendorDetails] = useState({});
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const invoice = useSelector((state) => state.invoice.invoice);
  const vendor = useSelector((state) => state.vendor);
  const loading = useSelector((state) => state.commonReducer.loader);
  const fileLoader = useSelector((state) => state.commonReducer.fileLoader);
  const contractFileUrl = useSelector(
    (state) => state.contract.associatedContractFile
  );
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );
  const fileUrl = useSelector((state) => state.po.poFileUrl);

  const invoiceFileUrl = useSelector((state) => state.invoice.invoiceFileUrl);

  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };
  useEffect(() => {
    dispatch(getVendors(organization.id));
    dispatch(getInvoiceById(id));
  }, []);

  useEffect(() => {
    setDicountedPriceToState();
    setVendorId(invoice.vendor_id);
  }, [invoice]);

  useEffect(() => {
    let vendorInfo =
      vendorId &&
      vendor &&
      vendor.vendors &&
      vendor.vendors.find((v) => v.id === vendorId);
    vendorInfo &&
      setVendorDetails({ id: vendorInfo.id, title: vendorInfo.title });
  }, [vendor, vendorId]);
  useEffect(async () => {
    if (fileUrl) {
      await openPDF(fileUrl);
      dispatch(clearPoFileUrl());
    }
  }, [fileUrl]);

  useEffect(async () => {
    if (contractFileUrl) {
      await openPDF(contractFileUrl);
      dispatch(clearContractFile());
    }
  }, [contractFileUrl]);

  useEffect(async () => {
    if (invoiceFileUrl) {
      await openPDF(invoiceFileUrl);
      dispatch(clearInvoiceFile());
    }
  }, [invoiceFileUrl]);

  let discrepancyCount = 0;
  invoice &&
    !isEmpty(invoice.discrepancy) &&
    invoice.discrepancies &&
    invoice.discrepancies.data &&
    invoice.discrepancies.data.forEach((disc) => {
      if (disc.fields) {
        discrepancyCount += disc.fields.length;
      }
    });

  const setDicountedPriceToState = () => {
    setInvoiceItems(invoice.invoice_items);
  };

  const updateStatus = (status) => {
    dispatch(
      updateInvoiceById(id, {
        status: status,
      })
    );
  };

  let color;
  for (let prop in badgeColor)
    if (prop === invoice.status) {
      color = badgeColor[prop];
    }

  const statusStyle = {
    border: `solid 2px ${color}`,
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: "50",
      render: (text, object, index) => index + 1,
      className: "remove-left-border",
    },
    {
      title: "SKU Number",
      dataIndex: "sku_number",
      key: "sku",
      render: (sku_number, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return (
          <div className="d-flex-justify-space-between">
            {sku_number && <p>{sku_number}</p>}
            {!isEmpty(isDiscrepancy) &&
              isDiscrepancy.fields.map((data) => {
                if (data.key === "sku_number")
                  return <span class="icon-warning"></span>;
              })}
          </div>
        );
      },
    },
    {
      title: "SKU Description",
      dataIndex: "item_name",
      key: "sku",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return (
          <div className="d-flex-justify-space-between">
            {quantity && (
              <p>{`${quantity
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
            )}
            {!isEmpty(isDiscrepancy) &&
              isDiscrepancy.fields.map((data) => {
                if (data.key === "quantity")
                  return <span class="icon-warning"></span>;
              })}
          </div>
        );
      },
    },
    {
      title: "Net Price",
      dataIndex: "final_price",
      key: "amount",
      render: (finalPrice, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return (
          <div className="d-flex-justify-space-between">
            {finalPrice && (
              <p>{`$${finalPrice
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
            )}
            {!isEmpty(isDiscrepancy) &&
              isDiscrepancy.fields.map((data) => {
                if (data.key === "final_price")
                  return <span class="icon-warning"></span>;
              })}
          </div>
        );
      },
    },
    {
      title: "Net Total",
      dataIndex: "total",
      key: "total",
      render: (total, record, index) => {
        const isDiscrepancy =
          invoice &&
          invoice.discrepancies &&
          invoice.discrepancies.data &&
          invoice.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {total && (
              <p>{`$${total
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</p>
            )}
            {!isEmpty(isDiscrepancy) &&
              isDiscrepancy.fields.map((data) => {
                if (data.key === "total")
                  return <span class="icon-warning"></span>;
              })}
          </div>
        );
      },
    },
  ];

  const displayRejectionModal = () => {
    setIsRejectModalOpen(true);
  };

  const displayApprovalModal = () => {
    setIsApprovalModalOpen(true);
  };

  const saveRejectionCommentWithReason = () => {
    const formValues = rejectForm.getFieldsValue();
    const data = {
      organization_id: organization.id,
      user_id: user.id,
      comment: formValues.comment,
      entity_id: id,
      entity_type: "invoice",
      status: "rejected",
    };
    rejectForm.resetFields();
    dispatch(createComment(data));
    updateStatus(Status.REJECTED);
  };

  const saveApprovalCommentWithReason = () => {
    const formValues = approveForm.getFieldsValue();
    const data = {
      organization_id: organization.id,
      user_id: user.id,
      comment: formValues.comment,
      entity_id: id,
      entity_type: "invoice",
      status: "approved w/ discrepancy",
    };
    approveForm.resetFields();
    dispatch(createComment(data));
    updateStatus(Status.APPROVED_WITH_DISCREPANCY);
  };
  const onFileSelect = (file) => {
    if (file.type == "invoice") {
      dispatch(getInvoiceFile(file.key));
    }
    if (file.type == "contract") {
      dispatch(getContractFile(file.key));
    } else if (file.type == "po") {
      dispatch(getPoFile(file.key));
    }
  };
  return (
    <Spin size="large" spinning={loading}>
      {isRejectModalOpen && (
        <Modal
          details="Reject Invoice"
          loading={loading}
          isOpen={isRejectModalOpen}
          okayText="Save"
          setParentClose={() => setIsRejectModalOpen(false)}
        >
          <Form
            form={rejectForm}
            id="form"
            layout="vertical"
            onFinish={() => {
              saveRejectionCommentWithReason();
            }}
          >
            <Form.Item label="Note" name="comment">
              <Input.TextArea rows={4} maxLength={200} showCount />
            </Form.Item>
          </Form>
        </Modal>
      )}
      {isApprovalModalOpen && (
        <Modal
          details="Approve Invoice"
          loading={loading}
          isOpen={isApprovalModalOpen}
          okayText="Save"
          setParentClose={() => setIsApprovalModalOpen(false)}
        >
          <Form
            form={approveForm}
            id="form"
            layout="vertical"
            onFinish={saveApprovalCommentWithReason}
          >
            <Form.Item label="Note" name="comment">
              <Input.TextArea rows={4} maxLength={200} showCount />
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Row className="content-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Invoice Details</h2>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="d-flex f-justify-end"
        >
          <Space>
            <Text className="f-info-label">Date</Text>
            <Text className="f-info-value">
              {invoice && invoice.createdAt && dateToLocal(invoice.createdAt)}
            </Text>
          </Space>
        </Col>
      </Row>

      <Row className="border-bottom-t1 mb-1-5">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="select-vendor-details-wrapper"
            >
              <div className="address-details">
                <p className="label-vendor-name">
                  {vendorDetails && vendorDetails.title}
                </p>
                <p className="label-vendor-number">+1 (575) 099 5820</p>
                <p>957 Linda Drive New Kensington, PA United States</p>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          xs={24}
          m={24}
          md={12}
          lg={12}
          xl={12}
          className="info-invoice-number"
        >
          <Row className="mb-0-5" gutter={[24, 24]} align="center">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Text className="f-info-label">Issue Date</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Text className="f-info-value">
                {invoice &&
                  invoice.issue_date &&
                  moment(invoice.issue_date).format("YYYY-MM-DD")}
              </Text>
            </Col>
          </Row>

          <Row className="mb-0-5" gutter={[24, 24]} align="center">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Text className="f-info-label">PO Number</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Text className="f-info-value">
                {invoice && invoice.invoice_number}
              </Text>
            </Col>
          </Row>

          <Row className="mb-0-5" gutter={[24, 24]} align="center">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Text className="f-info-label">Status</Text>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Text className="f-info-value status-tag-p1" style={statusStyle}>
                <Badge color={color} /> {invoice && invoice.status}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <h4 className="mt-1">Billing Details</h4>

      <Row className="form-view-t2 cus-section-mb-t1" gutter={24} align="left">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="mb-1">
            <Col className="col-label">
              <Text className="f-info-label">Contact Person</Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">
                {invoice && invoice.contact_person}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="col-label">
              <Text className="f-info-label">Contact Number </Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">
                {invoice && invoice.contact_number}
              </Text>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="mb-1">
            <Col className="col-label">
              <Text className="f-info-label">Email</Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">
                {invoice && invoice.invoice_email}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="col-label">
              <Text className="f-info-label">Address</Text>
            </Col>
            <Col className="col-value">
              <Text className="f-info-value">{invoice && invoice.address}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <h4 className="mt-1">SKU Details</h4>

      {invoice && invoice.invoice_items ? (
        <Table
          className="table-t2 table-discrepancies"
          size="middle"
          // loading={tableLoading}
          columns={columns}
          dataSource={invoice.invoice_items}
          expandable={{
            expandedRowRender: (record) => {
              let isDiscrepancy =
                invoice &&
                invoice.discrepancies &&
                invoice.discrepancies.data &&
                invoice.discrepancies.data.find(
                  (data) => data.sku_number === record.sku_number
                );
              return (
                <div className="discrepancy-message-box">
                  {!isEmpty(isDiscrepancy) &&
                    isDiscrepancy.fields.map((discrepancy) => {
                      if (invoice.invoice_wo_po) {
                        return (
                          <p>
                            <span className="icon-warning"></span>
                            <span className="label-msg">
                              {discrepancy.key == "quantity" &&
                                `Contractual Quantity Range is [${
                                  discrepancy.contract_skuMin
                                }-${
                                  discrepancy.contract_skuMax
                                    ? discrepancy.contract_skuMax
                                    : "infinite"
                                }]`}
                              {discrepancy.key == "final_price" &&
                                `Contractual Net Price
                            $${discrepancy.contract_value
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                              {discrepancy.key == "total" &&
                                `Invalid Net Total Value`}
                              {discrepancy.key == "sku_number" &&
                                `Invalid SKU number`}
                            </span>
                          </p>
                        );
                      } else {
                        return (
                          <p>
                            <span className="icon-warning"></span>
                            <span className="label-msg">
                              {discrepancy.key == "final_price" &&
                                `In respective purchase order Net Price
                              $${discrepancy.po_item_value
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                              {discrepancy.key == "quantity" &&
                                `In respective purchase order Quantity
                              ${discrepancy.po_item_value}`}
                              {discrepancy.key == "sku_number" &&
                                `Invalid SKU number`}
                              {discrepancy.key == "total" &&
                                `In respective purchase order Net Total
                              $${discrepancy.po_item_value
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            </span>
                          </p>
                        );
                      }
                    })}
                </div>
              );
            },
            defaultExpandAllRows: true,
            expandIcon: () => <></>,
            columnWidth: "0",

            rowExpandable: (record) =>
              invoice &&
              invoice.discrepancies &&
              invoice.discrepancies.data &&
              invoice.discrepancies.data.some(
                (data) => data.sku_number === record.sku_number
              ),
          }}
          pagination={false}
          summary={(pageData) => {
            let finalPrice = 0;

            pageData.forEach(({ total }) => {
              if (total && !isNaN(total)) finalPrice += total;
            });

            return (
              <>
                <Table.Summary.Row className="invoice-total-wrapper">
                  <Table.Summary.Cell
                    colSpan={6}
                    className="itw-row-2 align-right label-total-amount pr-2 pt-2"
                  >
                    <div className="label-descrepancy-in-data">
                      {discrepancyCount}{" "}
                      {discrepancyCount < 2 ? "discrepancy" : "discrepancies"}{" "}
                      found
                    </div>
                    Total Amount
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    colSpan={2}
                    className="itw-row-2 label-amount pt-2"
                  >
                    <Text type="">{`$${finalPrice
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      ) : (
        <Loader />
      )}
      {/* 
      <Row className="mt-2" gutter={24} align="top">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h6 className="sub-ht3">Attachments</h6>
          No file Attachments
        </Col>
      </Row> */}
      <Spin spinning={fileLoader}>
        {!isEmpty(invoice.document && invoice.document.key) && (
          <Row>
            <h4 className="mt-1">Uploaded Invoice</h4>
            <Col span={24} key={invoice.document.key}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onFileSelect(invoice.document);
                }}
              >
                <FilePdfOutlined /> {invoice.document.name}
              </a>
            </Col>
          </Row>
        )}
        {!isEmpty(invoice.associatedDocs) && (
          <Row>
            <h4 className="mt-1">Associated Documents</h4>
            {invoice.associatedDocs.map((d, i) => {
              return (
                <Col span={24}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onFileSelect(d);
                    }}
                  >
                    <FilePdfOutlined /> {d.name}
                  </a>
                </Col>
              );
            })}
          </Row>
        )}
      </Spin>
      <Row className="mt-2" gutter={24} align="top">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button
            className="btn-primary btn-t2 mr-1"
            onClick={() => {
              history.goBack();
            }}
          >
            <span class="btn-icon icon-arrow-square-left"></span> Back
          </Button>
          {invoice && invoice.status === Status.DISCREPANCY_APPROVAL && (
            <Button
              className="btn-primary btn-t3 mr-1"
              style={{ backgroundColor: "#fb7d59", border: "#fb7d59" }}
              onClick={displayRejectionModal}
            >
              <span className="btn-icon icon-block"></span> Reject
            </Button>
          )}
          {invoice && invoice.status === Status.DISCREPANCY_APPROVAL && (
            <Button className="btn-primary " onClick={displayApprovalModal}>
              <span className="btn-icon icon-tick"></span> Approve
            </Button>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default ApprovePO;
