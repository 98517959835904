import React from "react";
import { Modal, Button } from "antd";

const ConfirmBox = ({
  title,
  isOpen,
  setParentClose,
  children,
  width,
  details = "",
  handleOk = () => {},
  cancelText = "Cancel",
  okayText = "OK",
  loading = false,
  showFooterButtons = true,
}) => {
  const handleCancel = () => {
    setParentClose();
  };
  return (
    <Modal
      title={title}
      visible={isOpen && !loading}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      footer={
        showFooterButtons && [
          <Button className="btn-primary btn-t2" onClick={handleCancel}>
            <span className="btn-icon icon-cancel"></span> {cancelText}
          </Button>,
          <Button
            loading={loading}
            className="btn-primary"
            htmlType="submit"
            form="form"
            onClick={handleOk}
          >
            <span className="btn-icon icon-tick"></span> {okayText}
          </Button>,
        ]
      }
    >
      <h4 style={{ color: "#5a5a5a" }}>{details}</h4>
      {children}
    </Modal>
  );
};

export default ConfirmBox;
