import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Form,
  Typography,
  Avatar,
  DatePicker,
  Switch,
  Tooltip,
  Spin,
} from "antd";
import _ from "lodash";
import { FilePdfOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { UPLOAD_PO_ROUTE, VIEW_PO_ROUTE } from "../../Constants/routeNames";
import {
  actionDispatch,
  getPOById,
  updatePOWithPOItems,
  getVendors,
  getSelectedContractTemplate,
  getPoFile,
  getContractFile,
  triggerNotification,
  clearPoFileUrl,
  clearContractFile,
} from "../../actions/Creators";
import history from "../../History/history";
import Notification from "../../Common/Notification";
import VendorDropdown from "../../Common/VendorDropdown";
import { HIDE_NOTIFICATION } from "../../actions/Types";
import { Status } from "./POStatus";
import dummyVendorLogo from "../../assets/images/abc-aus.jpg";
import { isEmpty } from "../../utils/isEmpty";
import Authorized from "../../Role-Authorization/Authorized";
import { ROLES_PO_ADD_VIEW } from "../../utils/constants";
import { openPDF } from "../../utils/s3Upload";
import { emptyFileUrl } from "../../actions/Creators/Contracts";

const { Text } = Typography;
const discrepancyText = "Contractual";

const AddPO = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [tableData, setTableData] = useState([{}]);
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const [vendorInfo, setVendorInfo] = useState({});
  const [vendorId, setVendorId] = useState();
  const [isDisabled, setIsDisable] = useState(false);
  const vendor = useSelector((state) => state.vendor);
  const notification = useSelector((state) => state.commonReducer.notification);
  const po = useSelector((state) => state.po.po);
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.commonReducer.loader);
  const fileLoader = useSelector((state) => state.commonReducer.fileLoader);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );
  const [poWithContract, setPoWithContract] = useState(false);
  const fileUrl = useSelector((state) => state.po.poFileUrl);
  const contractFileUrl = useSelector(
    (state) => state.contract.associatedContractFile
  );
  useEffect(() => {
    dispatch(getVendors(organization.id));
    dispatch(getPOById(id));
  }, []);
  useEffect(() => {
    let vendorDetails =
      vendorId &&
      vendor &&
      vendor.vendors &&
      vendor.vendors.find((v) => v.id == vendorId);
    vendorDetails &&
      setVendorInfo({ id: vendorDetails.id, title: vendorDetails.title });
  }, [vendor, vendorId]);

  useEffect(() => {
    tableForm.resetFields();
    po &&
      po.vendor &&
      setVendorInfo({ key: po.vendor.id, value: po.vendor.title });
    if (
      po &&
      (po.status === Status.DISCREPANCY_PENDING ||
        po.status === Status.REJECTED ||
        po.status === Status.PROCESSED ||
        po.status === Status.TEMPLATE_NOT_SUPPORTED)
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    let newobj = {};
    po &&
      po.purchase_order_items &&
      po.purchase_order_items.forEach((obj, i) => {
        Object.keys(obj).forEach((key) => {
          newobj[`${key}-${i}`] = obj[key];
        });
      });
    tableForm.setFieldsValue({
      ...newobj,
    });
    po && po.vendor_id && setVendorId(po.vendor_id);
    po &&
      form.setFieldsValue({
        address: po.address,
        amount: po.amount,
        associated_po: po.associated_po,
        contact_number: po.contact_number,
        contact_person: po.contact_person,
        discrepancies: po.discrepancies,
        po_email: po.po_email,
        po_items: po.po_items,
        po_number: po.po_number,
        document_id: po.document_id,
        contract_associate: po.contract_associate,
        po_wo_contract: po.po_wo_contract,
        issue_date:
          po.issue_date && moment(po.issue_date).isValid()
            ? moment(po.issue_date)
            : null,
        delivery_date:
          po.delivery_date && moment(po.delivery_date).isValid()
            ? moment(po.delivery_date)
            : null,
      });
    setValueToTotalAmount();
    po && setPoWithContract(po.po_wo_contract);
  }, [po]);

  let discrepancyCount = null;
  po &&
    !isEmpty(po.discrepancy) &&
    po.discrepancies &&
    po.discrepancies.data &&
    po.discrepancies.data.forEach((disc) => {
      if (disc.fields) {
        discrepancyCount += disc.fields.length;
      }
    });

  const setValueToTotalAmount = () => {
    const updatedValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(updatedValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    let dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value) => {
        result[value] = updatedValues[`${value}-${index}`];
        return result;
      }, {});
    });
    const temp = [];
    let discount = 0;
    let newobj = {};

    dto.forEach((i, index) => {
      i.total = convertToCurrencyNumber(i.total);
      if (i.total && !isNaN(i.total)) temp.push(Number(i.total));
      else temp.push(0);
    });

    let finalDiscountPrice = 0;
    temp.forEach((amount) => {
      if (!isNaN(amount) || amount !== "") {
        finalDiscountPrice += amount;
      }
    });
    setTotalAmount(convertToCurrencyNumber(finalDiscountPrice));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      align: "center",
      render: (text, object, index) => index + 1,
    },
    {
      title: "SKU Number",
      dataIndex: "sku_number",
      key: "sku",
      render: (sku_number, record, index) => {
        const isDiscrepancy =
          po &&
          po.discrepancies &&
          po.discrepancies.data &&
          po.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "sku_number")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`sku_number-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Sku number.",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>
              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "sku_number") {
                    return (
                      <Tooltip
                        overlayClassName="table-tooltip"
                        title="Invalid Sku number"
                        color="#f87b57"
                        placement="bottom"
                      >
                        <span className="icon-warning"></span>
                      </Tooltip>
                    );
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "SKU Description",
      dataIndex: "item_name",
      key: "sku",
      render: (item_name, _, index) => (
        <Form.Item name={`item_name-${index}`}>
          <Input disabled={isDisabled} />
        </Form.Item>
      ),
    },
    // {
    //   title: "Subscriptions",
    //   dataIndex: "subscription",
    //   key: "subscription",
    //   render: (subscription, record, index) => {
    //     const isDiscrepancy =
    //       po &&
    //       po.discrepancies &&
    //       po.discrepancies.data &&
    //       po.discrepancies.data.find(
    //         (data) => data.sku_number === record.sku_number
    //       );
    //     return {
    //       props: {
    //         style: {
    //           border:
    //             !isEmpty(isDiscrepancy) &&
    //             isDiscrepancy.fields.some((data) => data.key === "subscription")
    //               ? "solid #fb7d59 2px"
    //               : "",
    //         },
    //       },
    //       children: (
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           {subscription && <p>{`${subscription}`}</p>}
    //           {!isEmpty(isDiscrepancy) &&
    //             isDiscrepancy.fields.map((data) => {
    //               if (data.key === "subscription") {
    //                 return (
    //                   <Tooltip
    //                     overlayClassName="table-tooltip"
    //                     title={`${discrepancyText} ${data.key} = ${data.contract_value}`}
    //                     color="#f87b57"
    //                     placement="bottom"
    //                   >
    //                     <span className="icon-warning"></span>
    //                   </Tooltip>
    //                 );
    //               }
    //             })}
    //         </div>
    //       ),
    //     };
    //   },
    // },
    // {
    //   title: "Users Per Subscription",
    //   dataIndex: "users_per_subscription",
    //   key: "usersPerSubscription",
    //   render: (usersPerSubscription, record, index) => {
    //     const isDiscrepancy =
    //       po &&
    //       po.discrepancies &&
    //       po.discrepancies.data &&
    //       po.discrepancies.data.find(
    //         (data) => data.sku_number === record.sku_number
    //       );
    //     return {
    //       props: {
    //         style: {
    //           border:
    //             !isEmpty(isDiscrepancy) &&
    //             isDiscrepancy.fields.some(
    //               (data) => data.key === "users_per_subscription"
    //             )
    //               ? "solid #fb7d59 2px"
    //               : "",
    //         },
    //       },
    //       children: (
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           {usersPerSubscription && <p>{`${usersPerSubscription}`}</p>}
    //           {!isEmpty(isDiscrepancy) &&
    //             isDiscrepancy.fields.map((data) => {
    //               if (data.key === "users_per_subscription") {
    //                 return (
    //                   <Tooltip
    //                     overlayClassName="table-tooltip"
    //                     title={`${discrepancyText} ${data.key} = ${data.contract_value}`}
    //                     color="#f87b57"
    //                     placement="bottom"
    //                   >
    //                     <span className="icon-warning"></span>
    //                   </Tooltip>
    //                 );
    //               }
    //             })}
    //         </div>
    //       ),
    //     };
    //   },
    // },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record, index) => {
        const isDiscrepancy =
          po &&
          po.discrepancies &&
          po.discrepancies.data &&
          po.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "quantity")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`quantity-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Quantity.",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  decimalScale={0}
                  disabled={isDisabled}
                  allowNegative={false}
                />
              </Form.Item>
              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "quantity") {
                    return (
                      <Tooltip
                        overlayClassName="table-tooltip"
                        title={`Contractual Quantity Range is [${
                          data.contract_skuMin
                        }-${
                          data.contract_skuMax
                            ? data.contract_skuMax
                            : "infinite"
                        }]`}
                        color="#f87b57"
                        placement="bottom"
                      >
                        <span className="icon-warning"></span>
                      </Tooltip>
                    );
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "Net price",
      dataIndex: "final_price",
      key: "final_price",
      ellipsis: true,
      render: (finalPrice, record, index) => {
        const isDiscrepancy =
          po &&
          po.discrepancies &&
          po.discrepancies.data &&
          po.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "final_price")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`final_price-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Net Total.",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isDisabled}
                  allowNegative={false}
                />
              </Form.Item>
              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "final_price") {
                    return (
                      <Tooltip
                        overlayClassName="table-tooltip"
                        title={`${discrepancyText} Net Price is $${data.contract_value
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        color="#f87b57"
                        placement="bottom"
                      >
                        <span className="icon-warning"></span>
                      </Tooltip>
                    );
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "Net Total",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      render: (total, record, index) => {
        const isDiscrepancy =
          po &&
          po.discrepancies &&
          po.discrepancies.data &&
          po.discrepancies.data.find(
            (data) => data.sku_number === record.sku_number
          );
        return {
          props: {
            style: {
              border:
                !isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.some((data) => data.key === "total")
                  ? "solid #fb7d59 2px"
                  : "",
            },
          },
          children: (
            <div className="discrepancies-cell-wrapper">
              <Form.Item
                name={`total-${index}`}
                rules={[
                  {
                    required: true,
                    message: "Please provide Net Total.",
                  },
                ]}
              >
                <NumberFormat
                  className="ant-input-number"
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isDisabled}
                  allowNegative={false}
                  onChange={setValueToTotalAmount}
                />
              </Form.Item>
              {!isEmpty(isDiscrepancy) &&
                isDiscrepancy.fields.map((data) => {
                  if (data.key === "total") {
                    return (
                      <Tooltip
                        overlayClassName="table-tooltip"
                        title="Invalid Net Total Value"
                        color="#f87b57"
                        placement="bottom"
                      >
                        <span className="icon-warning"></span>
                      </Tooltip>
                    );
                  }
                })}
            </div>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "actions",
      align: "center",
      width: po.status === Status.TEMPLATE_NOT_SUPPORTED ? "50px" : "0",
      render: (_, row, index) => {
        return (
          <>
            {po.status === Status.TEMPLATE_NOT_SUPPORTED && (
              <Tooltip title="Delete">
                <DeleteOutlined
                  style={{ cursor: "pointer" }}
                  className="clr-link"
                  onClick={() => deleteRow(row, index)}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const onUpdateHandler = async (status, shouldRecheck, comment) => {
    await form.validateFields();
    await tableForm.validateFields();
    const tableValues = tableForm.getFieldsValue();
    let maxRows = 0;
    let columnsList = Object.keys(tableValues).map((dt) => {
      maxRows = dt.split("-")[1];
      return `${dt.split("-")[0]}`;
    });
    columnsList = [...new Set(columnsList)];
    let dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index) => {
      return columnsList.reduce((result, value, colIndex) => {
        result[value] = tableValues[`${value}-${index}`];
        return result;
      }, {});
    });
    const duplications = [];
    dto = dto.map((record, index) => {
      for (let prop in record) {
        if (prop == "sku_number") {
          duplications.push(record[prop]);
        }
      }
      record.final_price =
        record.final_price && convertToCurrencyNumber(record.final_price);
      record.quantity =
        record.quantity && convertToCurrencyNumber(record.quantity);
      record.total = record.total && convertToCurrencyNumber(record.total);
      return { ...po.purchase_order_items[index], ...record };
    });

    let poDetails = {
      ...form.getFieldsValue(),
      issue_date: form.getFieldsValue().issue_date.format("YYYY-MM-DD"),
      delivery_date:
        form.getFieldsValue().delivery_date &&
        form.getFieldsValue().delivery_date.format("YYYY-MM-DD"),
      amount: totalAmount,
      vendor_id: vendorId,
      status: status,
    };
    let poItemDetails = [...dto];

    const checkDuplication = _.filter(duplications, (val, i, iteratee) => {
      return _.includes(iteratee, val, i + 1);
    });

    if (checkDuplication.length) {
      dispatch(
        triggerNotification("error", "SKU Number can not be duplicated")
      );
      return;
    }

    if (
      poDetails.issue_date &&
      poDetails.delivery_date &&
      moment(poDetails.issue_date).isAfter(moment(poDetails.delivery_date))
    ) {
      dispatch(
        triggerNotification(
          "error",
          "Delivery date should not be before the issue date."
        )
      );
      return;
    }

    tableForm.resetFields();

    let historyObj = {
      userId: user.id,
      comment: comment,
      status: status,
    };
    dispatch(
      updatePOWithPOItems(
        id,
        { poDetails, poItemDetails },
        historyObj,
        shouldRecheck
      )
    );
  };

  const convertToCurrencyNumber = (numString) => {
    let currency = numString && numString.toString().replace(/[$,%\s]/g, "");
    return Number(currency);
  };
  const onFileSelect = (file) => {
    if (file.type == "po") {
      dispatch(getPoFile(file.key));
    } else {
      dispatch(getContractFile(file.key));
    }
  };

  useEffect(async () => {
    if (fileUrl) {
      await openPDF(fileUrl);
      dispatch(clearPoFileUrl());
    }
  }, [fileUrl]);

  useEffect(async () => {
    if (contractFileUrl) {
      await openPDF(contractFileUrl);
      dispatch(clearContractFile());
    }
  }, [contractFileUrl]);

  const addRow = () => {
    let data = tableData;
    data.push({
      item_name: "",
      subscription: "",
      users_per_subscription: "",
      vat: "",
      final_price: "",
      po_id: "",
      total: "",
    });
    setTableData([...data]);
  };
  const deleteRow = (row, index) => {
    let data = tableData;
    if (tableData.length < 2) {
      dispatch(triggerNotification("error", "At least 1 SKU must be included"));
      return;
    } else {
      let fieldValues = tableForm.getFieldsValue();
      data = data.filter((d, i) => {
        return i !== index;
      });
      tableForm.resetFields();
      setTableData([...data]);
      for (let prop in fieldValues) {
        delete fieldValues[`${prop.split("-")[0]}-${index}`];
      }
      let obj = {};
      let maxRows;
      let columnsList = Object.keys(fieldValues).map((dt) => {
        maxRows = dt.split("-")[1];
        return `${dt.split("-")[0]}`;
      });
      columnsList = [...new Set(columnsList)];
      const dto = [...Array(parseInt(maxRows) + 1).keys()].map((_, index1) => {
        return columnsList.reduce((result, value, colIndex) => {
          if (index1 !== index) {
            result[value] = fieldValues[`${value}-${index1}`];
            return result;
          }
        }, {});
      });
      let filtered = dto.filter((el) => {
        return el != null;
      });

      filtered.map((d, i) => {
        Object.keys(d).map((dt) => {
          obj[`${dt}-${i}`] = d[dt];
        });
      });
      tableForm.setFieldsValue({
        ...obj,
      });
      setValueToTotalAmount();
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <Row className="content-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>Add Purchase Order</h2>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="d-flex f-justify-end"
        >
          <Link to={UPLOAD_PO_ROUTE}>
            <Button className="btn-primary">
              {" "}
              <span className="btn-icon icon-upload"></span>Upload New
            </Button>
          </Link>
        </Col>
      </Row>

      <Form
        form={form}
        validateTrigger="onBlur"
        className="form-vertical-t2"
        layout="vertical"
      >
        <Row className="">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="form-vertical-t2 form-horizontal-t2 form-layout-3">
              {" "}
              <Row className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="vendor" label="Vendor">
                    <VendorDropdown
                      disabled={isDisabled}
                      vendor={vendor}
                      id={po && po.vendor_id}
                      getValue={(val) => setVendorId(val.key)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Row className="">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="select-vendor-details-wrapper"
              >
                <div className="address-details">
                  <p className="label-vendor-name">
                    {vendorInfo && vendorInfo.title}
                  </p>
                  <p className="label-vendor-number">+1 (575) 099 5820</p>
                  <p>957 Linda Drive New Kensington, PA United States</p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="d-flex f-justify-end"
          >
            {" "}
            <div className="w-100 form-horizontal-t2 form-layout-2">
              <Form.Item
                label="Issue Date"
                name="issue_date"
                rules={[
                  {
                    required: true,
                    message: "Please provide the issue date",
                  },
                ]}
              >
                <DatePicker
                  format={"YYYY-MM-DD"}
                  disabled={isDisabled}
                  placeholder="YYYY-MM-DD"
                />
              </Form.Item>
              <Form.Item label="PO Number" name="po_number">
                <Input disabled={isDisabled} />
              </Form.Item>
              <Form.Item label="Po w/o contract" name="po_wo_contract">
                <Switch
                  disabled={isDisabled}
                  value={poWithContract}
                  checked={poWithContract}
                  onChange={(e) => setPoWithContract(e)}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <h4 className="mt-1">Billing Details</h4>
        <Row className="" gutter={24} align="top">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contact Person" name="contact_person">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Contact No." name="contact_number">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Address" name="address">
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>

        <Row className="" gutter={24} align="top">
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item
              label="Email"
              name="po_email"
              rules={[
                {
                  type: "email",
                  message: "Email is not valid.",
                },
              ]}
            >
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Form.Item label="Delivery Date" name="delivery_date">
              <DatePicker
                format={"YYYY-MM-DD"}
                disabled={isDisabled}
                placeholder="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
        </Row>

        <h4 className="mt-1">SKU Details</h4>
        <Form form={tableForm}>
          <Table
            className={`table-t2 table-form-inline ${
              po.status === Status.TEMPLATE_NOT_SUPPORTED
                ? ""
                : "table-add-po-sp"
            }`}
            size="middle"
            columns={columns}
            dataSource={
              po && po.status === Status.TEMPLATE_NOT_SUPPORTED
                ? tableData
                : po.purchase_order_items
            }
            pagination={false}
            summary={(pageData) => {
              let finalPrice = 0;

              pageData.forEach(({ final_price }) => {
                finalPrice += final_price;
              });

              return (
                <>
                  {/* <Table.Summary.Row className="invoice-total-wrapper">
                  <Table.Summary.Cell
                    colSpan={6}
                    className="itw-row-1 align-right pr-2"
                  >
                    VAT (13%)
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1} className="itw-row-1">
                    <Text type="" className="bold">
                      35%
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row> */}

                  <Table.Summary.Row className="invoice-total-wrapper">
                    {po.status === Status.TEMPLATE_NOT_SUPPORTED && (
                      <Table.Summary.Cell
                        colSpan={4}
                        className="itw-row-2 align-right label-total-amount pr-2"
                      >
                        <Button
                          className="btn-tertiary btn-clr-type3 float-left"
                          onClick={addRow}
                        >
                          <span className="btn-icon icon-add-new"></span> Add
                          Row
                        </Button>
                      </Table.Summary.Cell>
                    )}
                    {po.status !== Status.TEMPLATE_NOT_SUPPORTED && (
                      <Table.Summary.Cell
                        colSpan={4}
                        className="itw-row-2 align-right label-total-amount pr-2 pt-2"
                      >
                        {discrepancyCount && (
                          <div className="label-descrepancy-in-data">
                            {discrepancyCount}{" "}
                            {discrepancyCount < 2
                              ? "discrepancy"
                              : "discrepancies"}{" "}
                            found
                          </div>
                        )}
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell className="itw-row-2 align-right label-total-amount pr-2 pt-2">
                      <div className="float-right">Total Amount</div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="itw-row-2 label-amount pt-2 pl-0 pr-0">
                      <Text type="" className="has-bg">{`$${totalAmount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Form>
        <Spin spinning={fileLoader}>
          {!isEmpty(po.document && po.document.key) && (
            <Row>
              <h4 className="mt-1">Uploaded PO</h4>
              <Col span={24} key={po.document.key}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onFileSelect(po.document);
                  }}
                >
                  <FilePdfOutlined /> {po.document.name}
                </a>
              </Col>
            </Row>
          )}
          {!isEmpty(po.associatedContracts) && (
            <Row>
              <h4 className="mt-1">Associated Contracts</h4>
              {po.associatedContracts.map((d, i) => {
                return (
                  <Col span={24} key={d.key}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        onFileSelect(d);
                      }}
                    >
                      <FilePdfOutlined /> {d.name}
                    </a>
                  </Col>
                );
              })}
            </Row>
          )}
        </Spin>
        <Row className="mt-2" align="top">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button
              onClick={() => {
                history.goBack();
              }}
              className="btn-primary btn-t2 mr-1"
            >
              <span class="btn-icon icon-arrow-square-left"></span> Back
            </Button>
            {po &&
              (po.status === Status.DISCREPANCY_PENDING ||
                po.status === Status.REJECTED ||
                po.status === Status.PROCESSED ||
                po.status === Status.TEMPLATE_NOT_SUPPORTED) && (
                <Button
                  className="btn-primary mr-1"
                  onClick={() =>
                    onUpdateHandler(
                      Status.IN_PROCESS,
                      true,
                      "PO sent for discrepancy check"
                    )
                  }
                >
                  <span className="btn-icon icon-tick"></span> Confirm
                </Button>
              )}
            <Authorized roleList={ROLES_PO_ADD_VIEW}>
              <>
                {po &&
                  po.discrepancy &&
                  (po.status === Status.DISCREPANCY_PENDING ||
                    po.status === Status.REJECTED) && (
                    <Button
                      className="btn-primary"
                      onClick={() =>
                        onUpdateHandler(
                          Status.DISCREPANCY_APPROVAL,
                          false,
                          "PO sent for Approval"
                        )
                      }
                    >
                      <span className="btn-icon icon-tick"></span> Send for
                      Approval
                    </Button>
                  )}
              </>
            </Authorized>
          </Col>
        </Row>
      </Form>
      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
    </Spin>
  );
};

export default AddPO;
