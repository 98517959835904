import { actionDispatch } from "./actionDispatcher";
import { SHOW_NOTIFICATION, HIDE_NOTIFICATION, SHOW_FILE_LOADER, HIDE_FILE_LOADER, SHOW_LOADER, HIDE_LOADER, SET_THEME } from "../Types";
import { saveToLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";

export const triggerNotification = (type, message = "Something went wrong", time = 2000) => (
  dispatch
) => {
  dispatch(
    actionDispatch(SHOW_NOTIFICATION, {
      message,
      open: true,
      type,
      hideTime: time,
    })
  );
};
export const hideNotification = () => (dispatch) => {
  dispatch(actionDispatch(HIDE_NOTIFICATION, { open: false }));
};

export const showFileLoader = () => dispatch => {
  dispatch(actionDispatch(SHOW_FILE_LOADER))
}

export const hideFileLoader = () => dispatch => {
  dispatch(actionDispatch(HIDE_FILE_LOADER))
}

export const changeTheme = (theme) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  saveToLocal(theme, LOCAL_STORAGE_KEYS.THEME, false);
  dispatch(actionDispatch(SET_THEME, { theme }));
  dispatch(actionDispatch(HIDE_LOADER));
};
