import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import _ from "lodash";
import { Card, Badge, Row, Col } from "antd";
import { Column } from "@ant-design/charts";
import Loader from "../Loader";
import moment from 'moment';

const ColumnChart = ({
  dataSet,
  types,
  labelText = "",
}) => {
  const history = useHistory();
  
  const [indexNo, setIndexNo] = useState(0);

  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    const d = [...dataSet[0].data];
    setData(indexNo === 0 ? d.slice(0, 7) : d);

    types && setSelectedType(types[0]);
  }, [types]);

  var config = {
    data: data,
    color: dataSet[0].color,
    xField: dataSet[indexNo].xField,
    yField: dataSet[indexNo].yField,
    seriesField: '',
    height: 300,
    maxColumnWidth: 20,
    legend: false,
    tooltip: {
      formatter: (d) => {
        return { name: 'Contract Renewal', value: d.count };
      },
    },
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        if(args[0] && args[0].data) {
          const plotData = args[0].data.data;
          let firstDay, lastDay;

          if(plotData.month === "Overdue") {
            const date = new Date(), y = date.getFullYear(), m = date.getMonth();
            firstDay = moment(new Date(2020, 1, 1)).format('YYYY-MM-DD');
            lastDay = moment(new Date(y, m, 0)).format('YYYY-MM-DD');
          } else {
            firstDay = moment(new Date(plotData.year, plotData.mon, 1)).format('YYYY-MM-DD');
            lastDay = moment(new Date(plotData.year, plotData.mon + 1, 0)).format('YYYY-MM-DD');
          }
          
          const vendorUrl = plotData.vendor === '0' ? '' : `&vendor=${plotData.vendor}`;
          history.push(`/contracts/view?startDate=${firstDay}&endData=${lastDay}${vendorUrl}`);
        }
      });
    },
  };

  return (
    <Card className="dashboad-chart-box dashboard-card-t1 ">
      <Row className="mb-1">
        <Col xs={12} sm={12} md={24} lg={24} xl={12} xxl={10}>
          <h3>{labelText} </h3>
        </Col>

        <Col xs={12} sm={12} md={24} lg={24} xl={12} xxl={14}>
          {types && (
            <div className="db-tabs-buttons-wrapper-t2 float-right m-0 ">
              {types.map((type, i) => (
                <span
                  className={selectedType === type ? "activateLink" : ""}
                  onClick={() => {
                    const d = [...dataSet[0].data];
                    setSelectedType(type);
                    setData(i === 0? d.slice(0, 7): d);
                  }}
                >
                  {type}
                </span>
              ))}
            </div>
          )}

          <div className="db-legend-t1 ">
            {dataSet[indexNo] &&
              dataSet[indexNo].legend &&
              dataSet[indexNo].legend.map((t) => (
                <Badge text={t.type} color={t.color} />
              ))}
          </div>
        </Col>
      </Row>
      {dataSet && dataSet[indexNo] && !_.isEmpty(dataSet[indexNo].data) ? (
        <Column {...config} />
      ) : (
        <Loader />
      )}
    </Card>
  );
};

export default ColumnChart;
