import { Form, DatePicker, Select, Col, Radio, Input } from "antd";
import { Status as ContractStatus } from "../Components/Contracts/ContractStatus";
import moment from "moment";
import { organizations } from "../reducers/organizations";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const Filters = (props) => {
  const { isAuthorized } = props;
  return props.filters.map((filter, i) => {
    switch (filter) {
      case "RANGE_PICKER":
        return (
          <Col xs={12} sm={12} md={4} lg={10} xl={8} xxl={7}>
            <Form.Item label="Select date range" name="date_range">
              <RangePicker
                allowClear={false}
                onChange={(date) => {
                  props.setFilter &&
                    props.setFilter({
                      startDate: moment(date[0]).format("YYYY-MM-DD"),
                      endDate: moment(date[1]).format("YYYY-MM-DD"),
                    });
                }}
              />
            </Form.Item>
          </Col>
        );
      case "ORGANIZATION":
        return (
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={5}>
            {!isAuthorized && (
              <Form.Item label="Organization">
                <Input disabled={true} value={props.organization.name} />
              </Form.Item>
            )}
            {isAuthorized && (
              <Form.Item label="Organization" name="organization">
                <Select
                  defaultValue={""}
                  onChange={(value, options) => {
                    props.setFilter &&
                      props.setFilter({ organization: options.value });
                  }}
                >
                  <Option key="all" value="">
                    All
                  </Option>
                  {props.organizations.map((org, i) => (
                    <Option key={i} value={org.id}>
                      {org.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        );
      case "VENDOR":
        return (
          
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item label="Vendor" name="vendor">
              <Select
                defaultValue={""}
                onChange={(value, options) =>
                  props.setFilter && props.setFilter({ vendor: value })
                }
              >
                <Option key="all" value="">
                  All
                </Option>
                {props.vendors.map((vendor, i) => (
                  <Option key={i} value={vendor.id}>
                    {vendor.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        );
      case "CONTRACTS_STATUS":
        return (
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={4}>
            <Form.Item label="Status" name="status">
              <Radio.Group
                name="status"
                defaultValue="all"
                buttonStyle="solid"
                className="filter-group-radio-solid-t1"
                size="large"
                onChange={(e) => {
                  props.setFilter &&
                    props.setFilter({ status: e.target.value });
                }}
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value={ContractStatus.PROCESSED}>
                  Processed
                </Radio.Button>
                <Radio.Button value={ContractStatus.PENDING}>
                  Pending
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        );
      case "USER":
        return (
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={4}>
            <Form.Item label="Users" name="user">
              <Select
                defaultValue={""}
                onChange={(value, options) =>
                  props.setFilter && props.setFilter({ user: value })
                }
              >
                <Option key="all" value="">
                  All
                </Option>
                {props.users.map((user, i) => {
                  return (
                    <Option key={i} value={user.id}>
                      {`${user.first_name ? user.first_name : ""} ${
                        user.last_name ? user.last_name : ""
                      }`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        );
      case "DOC_TYPE":
        return (
          <Col xs={12} sm={12} md={4} lg={10} xl={10} xxl={6}>
            <Form.Item label="Document Type" name="doc_type">
              <Radio.Group
                defaultValue="all"
                name="doc_type"
                buttonStyle="solid"
                size="large"
                onChange={(e) => {
                  props.setFilter &&
                    props.setFilter({ doc_type: e.target.value });
                }}
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="po">POs</Radio.Button>
                <Radio.Button value="invoice">Invoices</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        );
    }
  });
};
