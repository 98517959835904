import * as ActionTypes from "../actions/Types";
import { getFromLocal } from "../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";

const initialState = {
  jwt: getFromLocal(LOCAL_STORAGE_KEYS.TOKEN, false, true) || {},
  user: getFromLocal(LOCAL_STORAGE_KEYS.USER, true, true) || {},
  roles: getFromLocal(LOCAL_STORAGE_KEYS.ROLES, true, true) || {},
  users: []
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        jwt: action.payload.jwt,
        roles: action.payload.roles,
      };
    case ActionTypes.SIGNIN_FAILURE:
      return { ...state };
    case ActionTypes.LOGOUT:
      return { ...state, user: {}, jwt: {}, roles: [] }; 
    case ActionTypes.UPDATE_USER: 
      return {...state, user: {...initialState.user, first_name: action.payload.first_name, last_name: action.payload.last_name}}
    case ActionTypes.GET_USERS:
        return { ...state, users: action.payload}
      default:
      return state;
  }
};
