import * as ActionTypes from "../actions/Types";

const initialState = {
  contract: {
    page: 1,
    limit: 10,
    isRedirectedFromChild: false,
  },
  invoice: {
    page: 1,
    limit: 10,
    isRedirectedFromChild: false,
  },
  po: {
    page: 1,
    limit: 10,
    isRedirectedFromChild: false,
  },
};

export const pagination = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PAGINATION_FOR_CONTRACT:
      return {
        ...state,
        contract: {
          page: action.payload.page,
          limit: action.payload.limit,
        },
      };
    case ActionTypes.SET_REDIRECT_STATUS_FOR_CONTRACT:
      return { ...state, contract: {...state.contract, isRedirectedFromChild: action.payload} };
      case ActionTypes.SET_PAGINATION_FOR_INVOICE:
        return {
          ...state,
          invoice: {
            page: action.payload.page,
            limit: action.payload.limit,
          },
        };
      case ActionTypes.SET_REDIRECT_STATUS_FOR_INVOICE:
        return { ...state, invoice: {...state.invoice, isRedirectedFromChild: action.payload} };
        case ActionTypes.SET_PAGINATION_FOR_PO:
          return {
            ...state,
            po: {
              page: action.payload.page,
              limit: action.payload.limit,
            },
          };
        case ActionTypes.SET_REDIRECT_STATUS_FOR_PO:
          return { ...state, po: {...state.po, isRedirectedFromChild: action.payload} };
    default:
      return state;
  }
};
