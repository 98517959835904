export const Status = {
  PENDING: "pending",
  PROCESSED: "processed",
  APPROVED_WITH_DISCREPANCY: "approved w/ discrepancy",
  DISCREPANCY_PENDING: "discrepancy-pending",
  DISCREPANCY_APPROVAL: "discrepancy-approval",
  IN_PROCESS: "in-process",
  REJECTED: "rejected",
  CONFIRMED: "confirmed",
  TEMPLATE_NOT_SUPPORTED: "template-not-supported",
};

export const badgeColor = {
  pending: "#FFA200",
  processed: "#00ACFF",
  "approved w/ discrepancy": "#389E0D",
  "discrepancy-pending": "#EEC500",
  "discrepancy-approval": "#546FF2",
  "discrepancy-approval": "#fb7d59",
  "in-process": "#979797",
  rejected: "#E82F20",
  confirmed: "#14CF89",
  "template-not-supported": "#E82F20",
};
