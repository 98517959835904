import React from "react";
import { Row, Col } from "antd";
import Upload_Temp from "../../assets/images/no-files-upoaded.svg";

const NoFilesChoosen = () => (
  <Row className="no-file-choose-wrapper">
    <Col xl={24}>
      <img src={Upload_Temp} alt="svg_icon" />
      <p className="label-no-file">No files uploaded yet</p>
      <p className="label-info-upload">
        Files you upload will start showing here and you can also add details to
        them !{" "}
      </p>
    </Col>
  </Row>
);

export default NoFilesChoosen;
