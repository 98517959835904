import { Menu, Layout, Image } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AppLogo from "../assets/images/app-logo.png";
import AppLogoDark from "../assets/images/app-logo-white.png";
import AppLogoCollapse from "../assets/images/app-logo-collapse.png";
import reportTemplates from "../Constants/reportTemplates";
import { setRedirectStatus } from "../actions/Creators/pagination";
import {  getFromLocal } from "../utils/cache";
import {
  LOCAL_STORAGE_KEYS
} from "../utils/constants";

import {
  DASHBOARD_ROUTE,
  VIEW_CONTRACTS_ROUTE,
  VIEW_INVOICES_ROUTE,
  VIEW_PO_ROUTE,
  USER_PROFILE_ROUTE,
  VIEW_REPORTS_ROUTE,
} from "../Constants/routeNames";
import {
  ROLES_CONTRACT_VIEW,
  ROLES_INVOICE_VIEW,
  ROLES_PO_VIEW,
  ROLES_INVOICE_MANAGER,
  ROLES_REPORT_VIEW,
  ROLES_GENERAL,
  ROLES_DISCREPANCY_APPROVER_VIEW,
} from "../utils/constants";

const { Sider } = Layout;

const menuItems = [
  {
    title: 'Dashboard',
    path: DASHBOARD_ROUTE,
    icon: <span className="menu-item-font-icon icon-dashboard"></span>,
    label: <span className="menu-item-label">Dashboard</span>,
    permissions: ROLES_GENERAL,
  },
  {
    title: 'Contracts',
    path: VIEW_CONTRACTS_ROUTE,
    icon: <span className="menu-item-font-icon icon-icon-contract"></span>,
    label: <span className="menu-item-label">Contracts</span>,
    permissions: ROLES_CONTRACT_VIEW,
  },
  {
    title: 'Purchase Orders',
    path: VIEW_PO_ROUTE,
    icon: <span className="menu-item-font-icon icon-purchase-order"></span>,
    label: <span className="menu-item-label">Purchase Orders</span>,
    public: false,
    permissions: ROLES_PO_VIEW,
  },
  {
    title: 'Invoices',
    path: VIEW_INVOICES_ROUTE,
    icon: <span className="menu-item-font-icon icon-file-invoice-dollar"></span>,
    label: <span className="menu-item-label">Invoices</span>,
    public: false,
    permissions: [...ROLES_INVOICE_VIEW, ...ROLES_INVOICE_MANAGER],
  },
  {
    title: 'Reports',
    path: VIEW_REPORTS_ROUTE,
    icon: <span className="menu-item-font-icon icon-pie-chart"></span>,
    label: <span className="menu-item-label">Reports</span>,
    permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_GENERAL],
  },
  {
    title: 'Settings',
    path: USER_PROFILE_ROUTE,
    icon: <span className="menu-item-font-icon icon-setting"></span>,
    label: <span className="menu-item-label">Settings</span>,
    public: true,
  },
];

const Sidebar = ({ collapsed }) => {
  const dispatch = useDispatch();
  const rolesArr = useSelector((state) => state.auth.roles);
  const myRoles = rolesArr.length
    ? rolesArr.map((roles) => roles.role.name)
    : [];
  const location = useLocation();
  const { pathname } = location;
  const setToDefaultPagination = () => {
    dispatch(setRedirectStatus(false, "contract"));
    dispatch(setRedirectStatus(false, "invoice"));
    dispatch(setRedirectStatus(false, "po"));
  };
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ height: "auto" }}
      className="tp_sidebar_main"
      width="220"
      collapsedWidth="70"
    >
      {!collapsed ? (
        <div className="side-logo">
          <Image src={ getFromLocal(LOCAL_STORAGE_KEYS.THEME, false) === 'dark-theme' ? AppLogoDark  : AppLogo } alt="Smart Contract" preview={false} />
        </div>
      ) : (
        <div className="collapse-side-logo">
          <Image src={AppLogoCollapse} alt="Smart Contract" preview={false} />
        </div>
      )}
      <Menu
        // mode="inline"
        selectedKeys={[pathname.split("/")[1] ? pathname.split("/")[1] : "0"]}
        defaultSelectedKeys={["0"]}
        className="primary-nav"
        mode="vertical"
      >
        {menuItems.map((d, i) => {
          if (d.public)
            return (
              <>
                {!d.subMenu && (
                  <Menu.Item
                    key={d.path.split("/")[1] ? d.path.split("/")[1] : "0"}
                  >
                    <Link to={d.path}>
                      {d.icon} {d.label}
                    </Link>
                  </Menu.Item>
                )}
              </>
            );
          else {
            return (
              <>
                {myRoles.some((r) => d.permissions.includes(r)) && (
                  <Menu.Item
                    to={d.path}
                    key={d.path.split("/")[1] ? d.path.split("/")[1] : "0"}
                    onClick={setToDefaultPagination}
                  >
                    <Link to={d.path}>
                      {d.icon} {d.label}
                    </Link>
                  </Menu.Item>
                )}
              </>
            );
          }
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
