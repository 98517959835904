import React, { useState } from "react";
import { Tooltip, Col } from "antd";
import { useEffect } from "react/cjs/react.development";

const ReportCounter = ({ counter, color }) => {
  const [tooltipText, setTooltipText] = useState("");
  const colors = ["#dff7f7", "#fdede9"];
  return (
    <Col xs={24} sm={12} md={6} lg={6} xl={6}
      className="report-counter"
      style={{ backgroundColor: color === 0 ? colors[0] : colors[1] }}
    >
      <Tooltip title={tooltipText}>
      
          <p className="report-counter__count">
            <b>{counter && counter.count}</b>
          </p>
          <p className="report-counter__title">{counter && counter.title}</p>
      
      </Tooltip>
    </Col>
  );
};

export default ReportCounter;
