import { axiosInstance as axios } from "./axios";

const HttpRequest = async (
  method,
  uri,
  data,
  headers = null,
  params = null
) => {
  const configuration = {
    method: method,
    url: uri,
  };
  if (headers !== null) {
    configuration.headers = headers;
  }
  if (params !== null) {
    configuration.params = params;
  }
  if (
    method === "post" ||
    method === "put" ||
    method === "delete" ||
    method === "patch"
  ) {
    configuration.data = data;
  }
  return await axios(configuration);
};

export default HttpRequest;
