import React, { useState, useEffect, useRef } from "react";
import {
  FilePdfOutlined,
  DownloadOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import TableData from "./TableData";
import { saveAs } from "file-saver";
import ReportTemplate from "./ReportTemplate";
import { useDispatch, useSelector } from "react-redux";
import { BlobProvider, pdf, PDFViewer } from "@react-pdf/renderer";
import { Form, Row, Col, Button, Spin } from "antd";
import history from "../History/history";
import Authorized from "../Role-Authorization/Authorized";
import {
  getOrganizations,
  getVendors,
  getPoInvoiceCountAndStatus,
  getProcessedPOAndInvoice,
  getAllContracts,
  getProcessedContractsWithTimeDiff,
  getLoginFrequencyData,
  getAllUsers,
  getProcessedAndRejectedPOInvoices,
  getAggregateDiscrepanciesOfPoInvoice,
  setFilterObject,
} from "../actions/Creators";
import { ROLES_DISCREPANCY_APPROVER_VIEW, ROLES_TRUPACTA_TEAM_VIEW } from '../utils/constants';
import { isAuthorized } from "../Services/Auth/auth";
import { Filters } from "./Filters";
import ColumnChart from "../Common/Charts/Column";
import ReportCounter from "../Reports/ReportCounters";

export default (props) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [reportTitle, setReportTitle] = useState("");
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    organization: null,
    vendor: null,
    status: null,
    doc_type: null,
  });
  const [text, setText] = useState("rows found");
  const [imageData, setImageData] = useState();

  const organizations = useSelector(
    (state) => state.organizations.organizationsList
  );
  const users = useSelector((state) => state.auth.users);
  const vendors = useSelector((state) => state.vendor.vendors);
  const stateFilters = useSelector((state) => state.reports.filters);
  const loader = useSelector((state) => state.commonReducer.loader);
  const rolesArr = useSelector((state) => state.auth.roles);
  const organization = useSelector(
    (state) => state.header.selectedOrganization
  );

  useEffect(() => {
    if (isAuthorized(ROLES_TRUPACTA_TEAM_VIEW, rolesArr)) {
      dispatch(getOrganizations());
    }
    stateFilters && setFilters({ ...filters, ...stateFilters });
  }, []);

  useEffect(() => {
    setFilters({ ...filters, ...stateFilters });
    form.setFieldsValue({ ...stateFilters });
  }, [form]);

  useEffect(() => {
    if (isAuthorized(ROLES_TRUPACTA_TEAM_VIEW, rolesArr)) {
      dispatch(getVendors(form.getFieldsValue().organization));
      dispatch(getAllUsers(form.getFieldsValue().organization));
    }
    if (isAuthorized(ROLES_DISCREPANCY_APPROVER_VIEW, rolesArr)) {
      dispatch(getVendors(organization.id));
      dispatch(getAllUsers(organization.id));
    }
  }, [form.getFieldsValue().organization]);

  useEffect(() => {
    form.getFieldsValue().vendor &&
      form.setFieldsValue({
        organization: vendors.find(
          (vendor) => vendor.id === form.getFieldsValue().vendor
        ).organization_id,
      });
    setFilters({
      ...filters,
      ...stateFilters,
      organization:
        form.getFieldsValue().organization &&
        form.getFieldsValue().organization,
    });
  }, [form.getFieldsValue().vendor]);

  useEffect(() => {
    form.getFieldsValue().user &&
      form.setFieldsValue({
        organization: users.find(
          (user) => user.id === form.getFieldsValue().user
        ).organization_id,
      });
    setFilters({
      ...filters,
      ...stateFilters,
      organization:
        form.getFieldsValue().organization &&
        form.getFieldsValue().organization,
    });
  }, [form.getFieldsValue().user]);

  // Dispatch action according to selected report //
  useEffect(() => {
    console.log(ROLES_TRUPACTA_TEAM_VIEW, rolesArr);
    let isRoleAuthorized = isAuthorized(ROLES_TRUPACTA_TEAM_VIEW, rolesArr);
    switch (props.label) {
      case "PO_INVOICE_COUNT_WITH_STATUS_REPORT":
        setReportTitle(
          filters.doc_type === "po"
            ? "Template not found for Purchase Orders"
            : filters.doc_type === "invoice"
            ? "Template not found for Invoices"
            : "Template not found for Purchase Orders and Invoices"
        );
        return dispatch(
          getPoInvoiceCountAndStatus(
            filters,
            filters.doc_type,
            isRoleAuthorized,
            organization.id
          )
        );
      case "PROCESSED_PO_INVOICE_WITH_COUNT":
        setReportTitle(
          filters.doc_type === "po"
            ? "Processed Purchase Orders"
            : filters.doc_type === "invoice"
            ? "Processed Invoices"
            : "Processed Purchase Orders and Invoices"
        );
        return dispatch(
          getProcessedPOAndInvoice(
            filters,
            filters.doc_type,
            isRoleAuthorized,
            organization.id
          )
        );
      case "TOTAL_NUMBER_OF_CONTRACTS":
        return dispatch(
          getAllContracts(filters, isRoleAuthorized, organization.id)
        );
      case "PROCESSED_CONTRACTS_WITH_TIME_DIFFERENCE":
        setReportTitle("Processed Contracts");
        return dispatch(
          getProcessedContractsWithTimeDiff(
            filters,
            isRoleAuthorized,
            organization.id
          )
        );
      case "LOG_IN_FREQUENCY":
        setReportTitle("Number of Login Attempts");
        return dispatch(
          getLoginFrequencyData(filters, isRoleAuthorized, organization.id)
        );
      case "APPROVED_REJECTED_PO_INVOICE_PERCENTAGE":
        setReportTitle(
          filters.doc_type === "po"
            ? "Approved/Rejected Purchase Orders"
            : filters.doc_type === "invoice"
            ? "Approved/Rejected Invoices"
            : "Approved/Rejected Purchase Orders and Invoices"
        );
        return dispatch(
          getProcessedAndRejectedPOInvoices(
            filters,
            filters.doc_type,
            isRoleAuthorized,
            organization.id
          )
        );
      case "DISCREPANCY_AGGREGATE_PO":
        setReportTitle("Discrepancy Amount Aggregate for PO");
        return dispatch(
          getAggregateDiscrepanciesOfPoInvoice(
            filters,
            "po",
            isRoleAuthorized,
            organization.id
          )
        );
      case "DISCREPANCY_AGGREGATE_INVOICE":
        setReportTitle("Discrepancy Amount Aggregate for Invoice");
        return dispatch(
          getAggregateDiscrepanciesOfPoInvoice(
            filters,
            "invoice",
            isRoleAuthorized,
            organization.id
          )
        );
    }
  }, [filters]);

  const report = useSelector((state) => state.reports.report);

  // Download Option //
  const downloadAsPdf = async () => {
    const blob = await pdf(
      <ReportTemplate
        img={imageData && imageData}
        columns={props.columns}
        data={report}
        reportTitle={props.title}
        label={props.label}
        counterFields={props.report.counterFields(report)}
      />
    ).toBlob();
    saveAs(blob, `${props.report.title}`);
  };

  const setFilter = (filter) => {
    setFilters({ ...filters, ...filter });
    dispatch(setFilterObject({ ...filters, ...filter }));
  };

  const onClearFilters = () => {
    dispatch(setFilterObject({}));
    form.resetFields();
    setFilters({
      startDate: null,
      endDate: null,
      organization: null,
      vendor: null,
      status: null,
    });
  };

  return (
    <Spin spinning={loader}>
      <Row gutter={[24, 24]} style={{ marginBottom: '30px' }}>
        <Button className="btn-primary" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
          Back to previous page
        </Button>
      </Row>
      <Row gutter={[24, 12]} justify="space-between">
        <Col>
          <h3>{reportTitle}</h3>
        </Col>
        <Col>
          <Button className="btn-tertiary mr-1" icon={<FilePdfOutlined />}>
            <BlobProvider
              document={
                <ReportTemplate
                  columns={props.columns}
                  data={report}
                  reportTitle={props.title}
                  label={props.label}
                  img={imageData}
                  counterFields={props.report.counterFields(report)}
                />
              }
            >
              {({ url }) => {
                return (
                  <a className="btn-tertiary mr-1" href={url} target="_blank">
                    View as PDF
                  </a>
                );
              }}
            </BlobProvider>
          </Button>
          <Button className="btn-tertiary" onClick={downloadAsPdf} icon={<DownloadOutlined />}>
            Download as PDF
          </Button>
        </Col>
      </Row>

      <Form form={form} layout="vertical">
        {/* Filter options */}
        <Row gutter={[24, 12]}>
          <Filters
            filters={props.filters}
            setFilter={setFilter}
            defaultFilters={filters}
            organizations={organizations}
            organization={organization}
            vendors={vendors}
            users={users}
            isAuthorized={isAuthorized(ROLES_TRUPACTA_TEAM_VIEW, rolesArr)}
          />
          <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
            <Button type="link" className="btb-clear-filter" onClick={onClearFilters} style={{ marginTop: '25px' }}>
              <span class="btn-icon icon-cross"></span> Clear Filters
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="db-row">
          <Authorized roleList={ROLES_TRUPACTA_TEAM_VIEW}>
            <ReportCounter
              color={0}
              counter={{
                title:
                  form.getFieldsValue().organization || organizations.length === 1 ? 'Organization' : 'Organizations',
                count: form.getFieldsValue().organization
                  ? organizations.find((org) => org.id === form.getFieldsValue().organization).name
                  : organizations.length,
              }}
            />
          </Authorized>
          <Authorized roleList={ROLES_DISCREPANCY_APPROVER_VIEW}>
            <ReportCounter
              color={0}
              counter={{
                title: 'Organization',
                count: organization.name,
              }}
            />
          </Authorized>
          {props.report && !props.report.showUsers && (
            <ReportCounter
              color={1}
              counter={{
                title: form.getFieldsValue().vendor || vendors.length === 1 ? 'Vendor' : 'Vendors',
                count: form.getFieldsValue().vendor
                  ? vendors.find((vendor) => vendor.id === form.getFieldsValue().vendor)
                    ? vendors.find((vendor) => vendor.id === form.getFieldsValue().vendor).title
                    : 'N/A'
                  : vendors.length,
              }}
            />
          )}
          {props.report && props.report.showUsers && (
            <ReportCounter
              color={1}
              counter={{
                title: form.getFieldsValue().user || users.length === 1 ? 'User' : 'Users',
                count: form.getFieldsValue().user
                  ? users.find((user) => user.id === form.getFieldsValue().user)
                    ? users.find((user) => user.id === form.getFieldsValue().user).first_name
                    : 'N/A'
                  : users.length,
              }}
            />
          )}
          {props.report &&
            Object.entries(props.report.counterFields(report, props.report.countTitle)).map(([key, value], index) => (
              <ReportCounter counter={{ title: key, count: value }} color={(index + 1) % 2 !== 0 ? 0 : 1} />
            ))}
        </Row>
        <Row gutter={[24, 24]} className="db-row">
          {props.report &&
            props.report.graphs &&
            props.report.graphs.map((graph) => (
              <Col xl={12}>
                <div ref={inputRef}>
                  <ColumnChart
                    dataSet={[
                      {
                        data: report && graph.dataFunction(report.rows),
                        xField: graph.xField,
                        yField: graph.yField,
                        interval: graph.interval,
                      },
                    ]}
                    labelText={graph.labelText}
                    formatter={graph.formatter ? graph.formatter : ''}
                  />
                </div>
              </Col>
            ))}
        </Row>
        <Row>
          <Col>
            <h4 className="mt-2">
              Results found{' '}
              <span style={{ fontSize: 15, color: 'black' }}>
                ({report.count} {text})
              </span>
            </h4>
          </Col>
        </Row>
      </Form>

      <TableData columns={props.columns} data={report && report.rows} loading={loader} />
    </Spin>
  );
};
